import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { InfinitySpin } from "react-loader-spinner";
import { Link } from "react-router-dom";

interface Article {
  _id: string;
  Headline: string;
  Subtitle?: string;
  "Short description": string;
  Content: string;
  slug: string;
  _created: Number;
  category?: string;
}

export default function Insights() {
  const query = useQuery<Article[]>({
    queryKey: ["articles"],
    queryFn: () =>
      axios
        .get(
          `${
            process.env.REACT_APP_GOMICA_CMS_API_URL
          }content/items/Articles?sort=${JSON.stringify({ _created: -1 })}`,
          {
            headers: {
              "API-KEY": process.env.REACT_APP_GOMICA_CMS_API_KEY,
            },
          }
        )
        .then((res) => res.data),
  });

  return (
    <>
      <h1 className="text-4xl text-svlaw-blue-light dark:text-svlaw-gold mb-4">
        Latest Insights
      </h1>
      {query.isLoading && <InfinitySpin width="200" color="royalblue" />}

      {query.data && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
          {query.data.map((article: Article) => (
            <div
              key={article._id}
              className="flex flex-col gap-2 items-start justify-start bg-slate-50 dark:bg-slate-700/90 rounded-xl shadow-xl p-6 border-2 border-slate-200 dark:border-slate-600"
            >
              <h2 className="font-bold text-xl text-slate-600 dark:text-slate-50">
                {article.Headline}
              </h2>
              {article.Subtitle && (
                <h3 className="font-medium text-md text-slate-500 dark:text-slate-300 -mt-2">
                  {article.Subtitle}
                </h3>
              )}
              <p className="text-sm">{article["Short description"]}</p>
              <Link
                to={`/insights/${article.slug}`}
                title={article.Headline}
                className="mt-auto font-bold flex flex-row items-center gap-2 text-yellow-500 hover:text-svlaw-gold transition-colors duration-500"
              >
                Read more <ArrowLongRightIcon className="h-6" />
              </Link>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
