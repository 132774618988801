import { InView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import Animation from "../../Animation";
import peopleAnimation from "../../animations/people-animation.json";
import checklistAnimation from "../../animations/checklist2-animation.json";
import { ArrowLongRightIcon } from "@heroicons/react/20/solid";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export default function Home() {
  const insightsQuery = useQuery({
    queryKey: ["articles"],
    queryFn: () =>
      axios
        .get(
          `${
            process.env.REACT_APP_GOMICA_CMS_API_URL
          }content/items/Articles?sort=${JSON.stringify({
            _created: -1,
          })}&limit=4`,
          {
            headers: {
              "API-KEY": process.env.REACT_APP_GOMICA_CMS_API_KEY,
            },
          }
        )
        .then((res) => res.data),
  });
  return (
    <>
      <section className="h-auto flex justify-center items-center -mx-4 px-4 pt-8">
        <div className="flex flex-col md:flex-row w-full items-center relative before:content-[''] before:bg-gradient-radial before:blur-2xl before:from-yellow-500 before:to-transparent before:absolute before:w-full before:max-w-xl before:opacity-30 before:-translate-x-2/3 before:lg:-translate-x-1/4 before:self-center before:flex before:flex-row before:h-96 after:content-[''] after:blur-2xl after:translate-x-[40%] after:bg-gradient-radial after:to-transparent after:from-sky-400 after:absolute after:w-full after:max-w-2xl after:h-96 after:-z-10 after:opacity-30">
          <div className="flex flex-col flex-grow gap-0 items-start justify-start text-4xl sm:text-5xl lg:text-[4.4rem] text-svlaw-blue-light dark:text-sky-300 mb-1 text-justify lg:mb-6 lg:mt-8 tracking-tight">
            <div className="dark:p-4 dark:mb-2 relative font-bold drop-shadow-lg text-indigo-400 mt-4 dark:bg-white/80 rounded-xl">
              <span>Go</span>
              <span className="text-blue-900">MiCA</span>
            </div>
            <h1 className="relative text-left font-medium text-zinc-800 dark:text-zinc-50 drop-shadow-lg">
              Your Gateway to MiCA Compliance
            </h1>
          </div>
          <div className="hidden sm:block my-8 z-10">
            <img
              src="/images/eu_flag_sketch.png"
              title="GoMiCA"
              alt="GoMiCA"
              className="max-w-[400px]"
            />
          </div>

          <div className="w-full sm:hidden flex justify-center my-4">
            <img
              src="/images/eu_flag_sketch.png"
              title="GoMiCA"
              alt="GoMiCA"
              className="mb-8 max-w-[300px]"
            />
          </div>
        </div>
      </section>
      <section className="mt-0 lg:mt-8">
        <p className="text-justify text-lg lg:text-xl pb-3 mt-2 lg:mt-0">
          The Markets in Crypto-Assets Regulation (MiCA) is a comprehensive
          framework for regulating crypto-assets in the European Union. It
          became part of EU law on 29 June 2023.
        </p>
        <p className="text-justify text-lg lg:text-xl pb-3 mt-2 lg:mt-0">
          This website — GoMiCA — aims to provide comprehensive and up-to-date
          information on MiCA, its implementation and its impact on stakeholders
          within the crypto and financial sectors. GoMiCA also provides tools
          and access to resources to help industry participants prepare for and
          comply with MiCA's provisions.
        </p>

        <InView triggerOnce threshold={0.2}>
          {({ inView, ref }) => (
            <div
              ref={ref}
              className={classNames(
                "transition-opacity duration-1000",
                inView ? "opacity-100" : "opacity-0"
              )}
            >
              <div className="flex flex-col md:flex-row-reverse items-center gap-2 md:gap-8">
                {/* <img
                  src="/images/checkliste.svg"
                  className="w-full md:max-w-sm flex-shrink-0"
                  title="Checklist"
                  alt="Checklist"
                /> */}
                <div className="flex-grow-0">
                  <h3 className="text-xl text-left text-svlaw-blue-light dark:text-svlaw-gold font-bold mt-8 md:mt-16 pb-2">
                    What are the main requirements of MiCA?
                  </h3>
                  <p className="pb-3 text-justify">
                    MiCA introduces the obligation to draw up a{" "}
                    <b>crypto-asset white paper</b> before offering
                    crypto-assets to the public or listing crypto-assets on an
                    exchange. MiCA sets out specific requirements regarding the
                    form and content of the crypto-asset white paper.
                  </p>

                  <p className="pb-3 text-justify">
                    Additional requirements apply to issuers of{" "}
                    <b>asset-referenced tokens</b> and <b>e-money tokens</b>.
                    Not only do these issuers require a <b>license</b>, the
                    crypto-asset white paper must be <b>approved</b> by their
                    financial regulator.
                  </p>
                  <p className="pb-3 text-justify">
                    Certain types of <b>crypto-asset service providers</b>{" "}
                    providing services in the European Union must obtain a{" "}
                    <b>license</b> from their financial regulator.
                  </p>
                  <p className="pb-3 text-justify">
                    MiCA also <b>prohibits market abuse</b> in the crypto
                    markets, including market manipulation and insider dealing.
                  </p>
                </div>
                {inView && (
                  <Animation
                    className="w-80 flex-shrink-0"
                    animationData={checklistAnimation}
                    loop={false}
                  />
                )}
              </div>
            </div>
          )}
        </InView>

        <InView triggerOnce threshold={0.2}>
          {({ inView, ref }) => (
            <div
              ref={ref}
              className={classNames(
                "transition-opacity duration-1000 flex flex-col-reverse md:flex-row-reverse items-center md:justify-start gap-2 md:gap-8 my-8",
                inView ? "opacity-100" : "opacity-0"
              )}
            >
              {/* <img
                src="/images/europe.svg"
                className="w-full md:max-w-md"
                title="Europe"
                alt="Europe"
              /> */}
              {inView && (
                <Animation
                  animationData={peopleAnimation}
                  className="max-w-xs flex-shrink-0 drop-shadow-xl"
                />
              )}
              <div className="flex-grow">
                <h3 className="text-xl text-center md:text-left text-svlaw-blue-light dark:text-svlaw-gold font-bold mt-4">
                  Who is affected by MiCA?
                </h3>
                <p className="py-2">
                  MiCA applies to issuers of crypto-assets, offerors, persons
                  seeking admission to trading of crypto-assets and crypto-asset
                  service providers. These include, for example:
                </p>
                <table className="table-fixed mt-3">
                  <tbody>
                    <tr>
                      <td>
                        <ul className="list-disc list-outside pl-8">
                          {" "}
                          <li>Token issuers</li>
                          <li>Stablecoin issuers</li>
                          <li>Custodial wallet providers</li>
                          <li>Crypto exchanges</li>
                          <li>Fiat on- and off-ramps</li>
                        </ul>
                      </td>
                      <td>
                        <ul className="list-disc list-outside pl-8">
                          {" "}
                          <li>Crypto brokers</li>
                          <li>Placement agents</li>
                          <li>Investment advisors</li>
                          <li>Portfolio advisors</li>
                          <li>Remittance providers</li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </InView>

        <InView triggerOnce threshold={0.2}>
          {({ inView, ref }) => (
            <div
              ref={ref}
              className={classNames(
                "transition-opacity duration-1000",
                inView ? "opacity-100" : "opacity-0"
              )}
            >
              <h3 className="text-xl text-center md:text-left text-svlaw-blue-light dark:text-svlaw-gold font-bold mt-8 md:mt-16 pb-2">
                When is compliance required?
              </h3>
              <p className="pb-3 text-justify">
                MiCA entered into force on 29 June 2023 and is currently
                undergoing a multi-year implementation phase. During the
                implementation phase, ESMA (European Securities and Markets
                Authority) and the EBA (European Banking Authority) are tasked
                with developing technical and regulatory implementing standards
                on a variety of topics. These standards are being developed in
                conjunction with public consultations, which allow stakeholders,
                industry experts, and the general public to provide valuable
                feedback and insights.
              </p>
              <p className="pb-3 text-justify">
                MiCA's provisions on asset-reference tokens (Title III) and
                e-money tokens (Title IV) will be applicable starting in June
                2024. All other provisions will be applicable from December
                2024.
              </p>
              <p className="pb-3 text-justify">
                You can find the latest information on our{" "}
                <Link
                  to="/resources"
                  className="text-svlaw-gold underline underline-offset-2"
                >
                  Resources
                </Link>{" "}
                page.
              </p>
            </div>
          )}
        </InView>

        <InView triggerOnce threshold={0.2}>
          {({ inView, ref }) => (
            <div
              ref={ref}
              className={classNames(
                "transition-opacity duration-1000",
                inView ? "opacity-100" : "opacity-0"
              )}
            >
              <div className="w-full flex flex-col justify-start items-start gap-2 mt-8">
                <a
                  href="https://www.esma.europa.eu/sites/default/files/inline-images/MiCA_Implementation_timeline.png"
                  title="Source of MiCA Timeline"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/mica-timeline.png"
                    title="MiCA Timeline"
                    alt="MiCA Timeline"
                    className="w-full rounded-xl shadow-xl"
                  />
                </a>
              </div>
            </div>
          )}
        </InView>

        <InView triggerOnce threshold={0.2}>
          {({ inView, ref }) => (
            <div
              ref={ref}
              className={classNames(
                "transition-opacity duration-1000",
                inView ? "opacity-100" : "opacity-0"
              )}
            >
              <h3 className="text-xl text-center md:text-left text-svlaw-blue-light dark:text-svlaw-gold font-bold mt-8 md:mt-16 pb-2">
                What information is available on GoMiCA?
              </h3>
              <p className="pb-3 text-justify">
                Here's what you can find on the website:
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <Link
                  to="/mica"
                  className="rounded-lg shadow-xl bg-white dark:bg-slate-700 dark:border dark:border-slate-500 py-6 px-8 flex flex-col hover:scale-110 transition-all duration-500"
                >
                  <h4 className="font-bold text-yellow-500">MiCA Overview</h4>
                  <p className="m-0 p-0 mt-2 text-sm text-slate-600 dark:text-slate-300 mb-3">
                    Learn about the new rules that apply to the crypto industry.
                  </p>
                  <span className="group flex flex-row gap-2 items-center justify-start text-xs mt-auto text-svlaw-blue-light dark:text-sky-200 transition-all duration-500 hover:text-svlaw-gold">
                    Learn more{" "}
                    <ArrowRightIcon className="h-3 group-hover:translate-x-1 transition-all" />
                  </span>
                </Link>
                <Link
                  to="/token-checker"
                  className="rounded-lg shadow-xl bg-white dark:bg-slate-700 dark:border dark:border-slate-500 py-6 px-8 flex flex-col hover:scale-110 transition-all duration-500"
                >
                  <h4 className="font-bold text-yellow-500">Token Checker</h4>
                  <p className="m-0 p-0 mt-2 text-sm text-slate-600 dark:text-slate-300 mb-3">
                    Check the legal status of your token and the associated
                    requirements.
                  </p>
                  <span className="group flex flex-row gap-2 items-center justify-start text-xs mt-auto text-svlaw-blue-light dark:text-sky-200 transition-all duration-500 hover:text-svlaw-gold">
                    Learn more{" "}
                    <ArrowRightIcon className="h-3 group-hover:translate-x-1 transition-all" />
                  </span>
                </Link>
                <Link
                  to="/resources"
                  className="rounded-lg shadow-xl bg-white dark:bg-slate-700 dark:border dark:border-slate-500 py-6 px-8 flex flex-col hover:scale-110 transition-all duration-500"
                >
                  <h4 className="font-bold text-yellow-500">Resources</h4>
                  <p className="m-0 p-0 mt-2 text-sm text-slate-600 dark:text-slate-300 mb-3">
                    Access relevant documents related to MiCA.
                  </p>
                  <span className="group flex flex-row gap-2 items-center justify-start text-xs mt-auto text-svlaw-blue-light dark:text-sky-200 transition-all duration-500 hover:text-svlaw-gold">
                    Learn more{" "}
                    <ArrowRightIcon className="h-3 group-hover:translate-x-1 transition-all" />
                  </span>
                </Link>
                <Link
                  to="/contact"
                  className="rounded-lg shadow-xl bg-white dark:bg-slate-700 dark:border dark:border-slate-500 py-6 px-8 flex flex-col hover:scale-110 transition-all duration-500"
                >
                  <h4 className="font-bold text-yellow-500">Contact</h4>
                  <p className="m-0 p-0 mt-2 text-sm text-slate-600 dark:text-slate-300 mb-3">
                    Reach out to us for further questions and personalized
                    assistance.
                  </p>
                  <span className="group flex flex-row gap-2 items-center justify-start text-xs mt-auto text-svlaw-blue-light dark:text-sky-200 transition-all duration-500 hover:text-svlaw-gold">
                    Learn more{" "}
                    <ArrowRightIcon className="h-3 group-hover:translate-x-1 transition-all" />
                  </span>
                </Link>
                <Link
                  to="/insights"
                  className="rounded-lg shadow-xl bg-white dark:bg-slate-700 dark:border dark:border-slate-500 py-6 px-8 flex flex-col hover:scale-110 transition-all duration-500"
                >
                  <h4 className="font-bold text-yellow-500">Insights</h4>
                  <p className="m-0 p-0 mt-2 text-sm text-slate-600 dark:text-slate-300 mb-3">
                    Stay updated with the latest news and developments.
                  </p>
                  <span className="group flex flex-row gap-2 items-center justify-start text-xs mt-auto text-svlaw-blue-light dark:text-sky-200 transition-all duration-500 hover:text-svlaw-gold">
                    Learn more{" "}
                    <ArrowRightIcon className="h-3 group-hover:translate-x-1 transition-all" />
                  </span>
                </Link>
                <Link
                  to="/about-us"
                  className="rounded-lg shadow-xl bg-white dark:bg-slate-700 dark:border dark:border-slate-500 py-6 px-8 flex flex-col hover:scale-110 transition-all duration-500"
                >
                  <h4 className="font-bold text-yellow-500">About Us</h4>
                  <p className="m-0 p-0 mt-2 text-sm text-slate-600 dark:text-slate-300 mb-3">
                    Get to know STADLER VÖLKEL, the law firm behind GoMiCA.
                  </p>
                  <span className="group flex flex-row gap-2 items-center justify-start text-xs mt-auto text-svlaw-blue-light dark:text-sky-200 transition-all duration-500 hover:text-svlaw-gold">
                    Learn more{" "}
                    <ArrowRightIcon className="h-3 group-hover:translate-x-1 transition-all" />
                  </span>
                </Link>
              </div>
            </div>
          )}
        </InView>
      </section>

      {insightsQuery.data && (
        <section>
          <InView triggerOnce threshold={0.2}>
            {({ inView, ref }) => (
              <div
                ref={ref}
                className={classNames(
                  "transition-opacity duration-1000",
                  inView ? "opacity-100" : "opacity-0"
                )}
              >
                <h3 className="text-xl text-center md:text-left text-svlaw-blue-light dark:text-svlaw-gold font-bold mt-8 md:mt-16 pb-2">
                  Latest Insights
                </h3>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                  {insightsQuery.data.map(
                    (article: {
                      _id: string;
                      Headline: string;
                      Subtitle?: string;
                      "Short description": string;
                      Content: string;
                      slug: string;
                      _created: Number;
                    }) => (
                      <Link
                        key={article._id}
                        to={`/insights/${article.slug}`}
                        title={article.Headline}
                        className="flex flex-col gap-2 items-start justify-start bg-slate-50 dark:bg-slate-700/90 rounded-xl shadow-xl p-4 hover:scale-110 transition-all duration-500"
                      >
                        <h2 className="font-bold text-lg text-slate-600 dark:text-slate-50 tracking-tight leading-5">
                          {article.Headline}
                        </h2>
                        {article.Subtitle && (
                          <h3 className="font-medium text-md text-slate-500 dark:text-slate-300 -mt-1 leading-5">
                            {article.Subtitle}
                          </h3>
                        )}
                        <p className="text-sm">
                          {article["Short description"]}
                        </p>
                        <span className="mt-auto font-bold flex flex-row items-center gap-2 text-yellow-500 hover:text-svlaw-gold transition-colors duration-500">
                          Read more <ArrowLongRightIcon className="h-6" />
                        </span>
                      </Link>
                    )
                  )}
                </div>
              </div>
            )}
          </InView>
        </section>
      )}
    </>
  );
}
