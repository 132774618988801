import NavigationLink from "./NavigationLink";

interface MicaChapterNavigationProps {
  urls: {
    title: string;
    href: string;
  }[];
}

export default function MicaChapterNavigation(
  props: MicaChapterNavigationProps = { urls: [] }
) {
  return (
    <div className="justify-between items-center my-3 hidden lg:flex">
      {props.urls.map((u) => (
        <NavigationLink
          key={u.href}
          href={u.href}
          className="text-center text-bold text-sm text-svlaw-blue-light dark:text-slate-300 transition-all duration-300 hover:text-svlaw-blue-dark dark:hover:text-white"
          activeClassName="text-svlaw-gold dark:text-svlaw-gold"
        >
          {u.title}
        </NavigationLink>
      ))}
    </div>
  );
}
