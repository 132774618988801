import { Link } from "react-router-dom";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import tokenCheckerAnimation from "../../animations/token-checker-animation.json";
import Animation from "../../Animation";

export default function QuestionnaireLandingPage() {
  return (
    <>
      <h1 className="text-4xl text-svlaw-blue-light dark:text-svlaw-gold">
        Welcome to the Token Checker!
      </h1>
      <div className="flex flex-row gap-8 justify-start items-start mt-4">
        <div>
          <h2 className="text-lg text-slate-700 dark:text-slate-50 my-4 text-justify">
            The Token Checker can be used to help determine the legal
            classification of a token under MiCA and to check whether a
            crypto-asset white paper is required.
          </h2>
          <p className="text-lg text-slate-700 dark:text-slate-50 text-justify">
            Using this tool, you can input the characteristics and features of a
            specific token, and the Token Checker will analyze the information
            to classify the token under the appropriate category as defined by
            MiCA. Additionally, the Token Checker will inform you if your
            offering requires a crypto-asset white paper according to MiCA
            regulations.
          </p>
        </div>
        <Animation
          animationData={tokenCheckerAnimation}
          loop={false}
          className="w-80 flex-shrink-0 hidden md:block"
        />
      </div>
      <div className="flex flex-col gap-0 mt-2 items-start">
        <Link
          to="/token-checker/whitepaper"
          className="inline-flex flex-row gap-2 items-center border-2 mt-2 border-svlaw-blue-dark duration-300 hover:bg-svlaw-gold rounded-md px-6 py-2 bg-svlaw-blue-light text-white shadow-md group"
        >
          Start
          <ArrowRightIcon className="w-5 group-hover:ml-4 transition-all duration-700 block" />
        </Link>
      </div>
      <p className="text-sm italic text-slate-500 dark:text-slate-300 mt-8 bg-white dark:bg-slate-800 rounded-md shadow-xl px-8 py-6 border-2 border-slate-300 dark:border-slate-600">
        Please note that the Token Checker is meant to serve as a general guide
        and should not be considered as legal advice. While it can provide
        valuable insights, it is always essential to consult with legal experts
        to ensure full compliance with the MiCA regulations and any other
        applicable laws in your jurisdiction.
      </p>
    </>
  );
}
