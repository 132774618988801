import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { HomeIcon } from "@heroicons/react/24/solid";
import MicaChapterNavigation from "../../MicaChapterNavigation";
import micaChapters from "../../..//MicaChapters.json";
import AnchorButton from "../../../AnchorButton";
import MobileMicaChapterNavigation from "../../MobileMicaChapterNavigation";

export default function PublicOfferOfCryptoAssets() {
  return (
    <section className="px-4 lg:px-0">
      <div className="text-justify">
        {/* Chapter-Navigation */}
        <MicaChapterNavigation urls={micaChapters} />
        {/* Breadcrumbs */}
        <div className="flex flex-row gap-2 mb-8 rounded-md bg-zinc-50/90 dark:bg-slate-950/80 shadow-sm px-4 py-2 static md:sticky md:top-24 backdrop-blur-md border-2 border-zinc-100 dark:border-slate-800">
          <Link
            to="/mica"
            className="text-slate-400 font-bold inline-flex flex-row gap-1 items-center"
          >
            <HomeIcon className="text-slate-400 w-4" /> MiCA
          </Link>
          <ChevronRightIcon className="text-slate-600 w-4" />
          <span className="text-slate-600 font-bold dark:text-slate-50 hidden md:block">
            Public offer of crypto-assets
          </span>
          <MobileMicaChapterNavigation urls={micaChapters}>
            <span className="text-slate-600 font-bold dark:text-slate-50">
              Public offer of crypto-assets
            </span>
          </MobileMicaChapterNavigation>
        </div>

        <h4 className="text-md text-zinc-600 dark:text-zinc-400">
          Markets in Crypto-Assets Regulation (MiCA)
        </h4>
        <h1
          className="text-4xl text-svlaw-blue-dark mb-1 dark:text-svlaw-gold"
          id="public-offering"
        >
          Public offer of crypto-assets <AnchorButton href="#public-offering" />
        </h1>

        <section>
          <h2
            className="text-3xl text-svlaw-blue-light mb-1 mt-7 dark:text-sky-200"
            id="general-requirements"
          >
            1. General requirements for the public offer{" "}
            <AnchorButton href="#general-requirements" />
          </h2>
          <p>
            MiCA sets out general requirements for the public offering of
            crypto-assets within the Union. A public offer means a communication
            to persons in any form, and by any means, presenting sufficient
            information on the terms of the offer and the crypto-assets to be
            offered so as to enable prospective holders to decide whether to
            purchase those crypto-assets. Any communication, including
            publishing information on a website, can qualify as a public offer
            if it contains sufficient information to enable prospective holders
            to make an investment decision. Recommendations by influencers in
            video messages or at events can also constitute a public offer.
          </p>

          <p className="pt-2">
            A public offer of crypto-assets is subject to the following
            requirements:
          </p>
          <ul className="list-disc list-outside ml-4">
            <li className="py-1">
              Only <b>legal entities</b> may publicly offer crypto-assets in the
              Union. The legal entity does not have to be established in the
              Union, however.
            </li>
            <li className="py-1">
              A <b>crypto-asset white paper</b> must be drawn up, notified to
              the competent supervisory authority, and published prior to the
              start of the public offer. It must be publicly available for as
              long as persons hold the crypto-asset, i.e., potentially
              indefinitely. If an asset-referenced token is to be offered
              publicly, the white paper must also be approved by a supervisory
              authority (see further below).
            </li>
            <li className="py-1">
              If <b>marketing</b> is conducted, the marketing communications
              must be: clearly identifiable; fair, clear and not misleading;
              consistent with the information in the white paper; reference the
              white paper; and contain certain disclaimers and statements set
              forth in MiCA. For documentation purposes, marketing
              communications shall also be posted on the offeror&apos;s website.
              No marketing may be conducted prior to the publication of the
              white paper.
            </li>
          </ul>
          <p className="pt-2">
            In addition, all offerors must comply with certain general
            principles. These include, among other things, acting honestly,
            fairly and professionally, communicating in a fair, clear and
            non-misleading manner, avoiding and, if necessary, disclosing
            conflicts of interest, acting in the best interests of the holders
            of the crypto-assets, and generally treating all holders equally.
            Persons applying to trade a crypto-asset on a trading platform must,
            in principle, meet the same requirements as offerors.
          </p>

          <p className="pt-2">
            The general requirements for the public offer of crypto-assets do
            not apply if:
          </p>
          <ul className="list-disc list-outside ml-4">
            <li className="py-1">
              The crypto-asset is offered for <b>free</b>;
            </li>
            <li className="py-1">
              the crypto-asset is automatically created as a{" "}
              <b>reward for the maintenance of the distributed ledger</b> or the
              validation of transactions;
            </li>
            <li className="py-1">
              the offer concerns a <b>utility token</b> providing access to a
              good or service that exists or is in operation;
            </li>
            <li className="py-1">
              the holder of the crypto-asset has the right to use it only in
              exchange for goods and services in a{" "}
              <b>limited network of merchants</b> with contractual arrangements
              with the offeror.
            </li>
          </ul>
          <p className="pt-2">
            Even if one of the above exclusions is applicable, it does not mean
            that MiCA as a whole is inapplicable. The rules for crypto-asset
            service providers still apply. For crypto-assets used in the context
            of a limited network, MiCA provides a number of detailed rules.
          </p>
          <p className="pt-2">
            A special feature applies to the excluded crypto-assets presented
            above if they are not listed on a trading platform. In this case, no
            admission under MiCA is required even for custody and administration
            services and transfer services. This is intended to completely
            exempt, for example, in-house crypto-assets from MiCA.
          </p>
          <p className="pt-2">
            MiCA also includes exclusions based on the nature of the offering. A
            white paper is not required, and marketing communications do not
            need to be documented on the website if:
          </p>
          <ul className="list-disc list-outside ml-4">
            <li className="py-1">
              an offer is made to <b>fewer than 150 natural or legal persons</b>{" "}
              (note: it is not the number of buyers that is decisive, but the
              number of persons to whom an offer is made); or
            </li>
            <li className="py-1">
              over a period of 12 months, the total consideration of an offer{" "}
              <b>does not exceed 1 million euros</b>; or
            </li>
            <li className="py-1">
              an offer is addressed solely to <b>qualified investors</b> where
              the crypto-asset can only be held by such qualified investors.
              Qualified investors are, for example, regulated companies,
              particularly large companies, public bodies or institutional
              investors.
            </li>
          </ul>
        </section>
        <section>
          <h2
            className="text-3xl text-svlaw-blue-light mb-1 mt-7 dark:text-sky-200"
            id="whitepaper"
          >
            2. Crypto-asset white paper <AnchorButton href="#whitepaper" />
          </h2>
          <p>
            MiCA adopts the industry&apos;s practice of drawing up a white paper
            and prescribes what content it must contain. Anyone who drafts a
            crypto-asset white paper, even on a voluntary basis, must still
            adhere to MiCA&apos;s specifications regarding the form and content
            of the white paper.
          </p>
          <p className="pt-2">
            In addition to information about the offeror (or the person seeking
            for admission to trading), the white paper must contain information
            about the issuer (if different), information about the operator of
            the trading platform (in cases where it draws up the white paper),
            information about the project, the public offering, the
            crypto-asset, about the rights and obligations associated with the
            crypto-asset, information on the underlying technology, information
            on the risks, and information about adverse climate and
            environmental impacts of the consensus mechanism used. The last
            point is the compromise of a debate that arguably could have led to
            the outright ban of the proof-of-work consensus mechanism at the
            European level.
          </p>
          <p className="pt-2">
            The white paper must be written in a manner that is fair, clear and
            not misleading. It must not contain material omissions and must be
            presented in a concise and comprehensible form. It must contain
            certain statements and disclaimers and must not contain any
            assertions as regards the future value of the crypto-asset offered.
            As is known from the prospectus regime for transferable securities,
            the European regulators ESMA and the EBA will publish delegated acts
            on the exact structure of the white paper.
          </p>
          <p className="pt-2">
            The crypto-asset white paper may only be used for a public offering
            by the person who created it. If other persons also make a public
            offer of the same crypto-asset, these persons may use the white
            paper only if express consent has been given.
          </p>

          <p className="pt-2">
            The white paper must be submitted to the competent supervisory
            authority (FMA, BaFin, etc.) no later than 20 working days prior to
            the date of publication (and thus also prior to the start of the
            public offer). Marketing communications must be transmitted to the
            supervisory authority only upon request. Prior approval of the white
            paper (for crypto-assets that do not qualify as asset-referenced
            tokens or e-money tokens) is generally not required.
          </p>

          <p className="pt-2">
            After publication of the white paper, the crypto-assets may be
            offered throughout the Union (if desired and previously communicated
            to the supervisory authority). The supervisory authority responsible
            for the crypto-asset will handle communication in this regard with
            the supervisory authorities of other EU member states. There is thus
            a Union-wide passport for the public offering of crypto-assets.
          </p>

          <p className="pt-2">
            However, the one-time publication of the white paper is not enough.
            The white paper must be kept up to date as long as the public
            offering lasts or the crypto-assets are admitted to trading. If new
            circumstances arise, or if there are errors or inaccuracies in the
            white paper, it must be updated if this may affect the valuation of
            the crypto-assets. The amended white paper must be submitted to the
            authority at least seven working days prior to publication. Older
            versions of the white paper and marketing communications must remain
            publicly available on the offeror&apos;s website for at least ten
            years.
          </p>

          <p className="pt-2">
            In the case of utility tokens for goods or services that do not yet
            exist or are not yet provided, the public offering may not last
            longer than twelve months. There are certain special features for
            the white paper of asset-referenced tokens and of e-money tokens
            (see further below). However, the structure of the white paper is
            essentially the same.
          </p>
        </section>
        <section>
          <h2
            className="text-3xl text-svlaw-blue-light mb-1 mt-7 dark:text-sky-200"
            id="withdrawal-liability"
          >
            3. Right of withdrawal & liability{" "}
            <AnchorButton href="#withdrawal-liability" />
          </h2>
          <p>
            MiCA grants retail holders a right of withdrawal when acquiring
            crypto-assets in a public offering either directly from the offeror,
            or from a service provider placing crypto-assets for that offeror.
            No separate right of withdrawal is provided for asset-referenced
            tokens and e-money tokens in MiCA. Retail holders must be informed
            in advance about the existence of a right of withdrawal. A retail
            holder is any natural person who is acting for purposes which are
            outside that person&apos;s trade, business, craft or profession.
          </p>
          <p className="pt-2">
            The right of withdrawal gives retail holders the right to withdraw
            from the purchase within 14 calendar days without incurring any fees
            or costs and without being required to give reasons. The right of
            withdrawal is only excluded if the crypto-asset was already admitted
            to trading prior to the retail holder&apos;s purchase.
          </p>
          <p className="pt-2">
            Similar to the prospectus liability regime known under EU securities
            laws, MiCA establishes liability for the information contained in
            the white paper. This liability is stipulated in three places in
            MiCA: once for asset referenced tokens, once for e-money tokens and
            once for all other crypto-assets. The contents of the respective
            liability provisions are essentially identical. If the white paper
            contains information that is not complete, fair or clear or that is
            misleading, the company and the members of the administrative,
            management or supervisory bodies shall be liable to the holder of
            the crypto-asset for any loss incurred due to that infringement.
            This provision cannot be waived.
          </p>
        </section>
        <section>
          <h2
            className="text-3xl text-svlaw-blue-light mb-1 mt-7 dark:text-sky-200"
            id="public-offering-of-asset-referenced-tokens"
          >
            4. Public offering of asset-referenced tokens and e-money tokens{" "}
            <AnchorButton href="#public-offering-of-asset-referenced-tokens" />
          </h2>
          <p>
            The public offering of asset-referenced tokens is generally subject
            to an authorization requirement under MiCA. Credit institutions do
            not need to obtain a separate authorization, but they still must
            draw up a crypto-asset white paper and have it approved by the
            competent supervisory authority.
          </p>
          <p className="pt-2">
            The rules related to issuing asset-referenced tokens are relative
            extensive compared to the rules imposed on payment institutions,
            investment firms or other regulated entities. Issuers must have
            specific governance and operational measures in place. They also
            must maintain a reserve of assets backing the asset-referenced token
            and give token holders a permanent right of redemption. There are
            also specific rules on the custody of the reserve of assets. These
            rules help distinguish asset-referenced tokens from tokenized
            financial instruments, especially derivatives, which are outside the
            scope of MiCA.
          </p>

          <p className="pt-2">
            There are exceptions to the authorization requirement. A license is
            not required if:
          </p>
          <ul className="list-disc list-outside ml-4">
            <li className="py-1">
              over a period of 12 months, calculated at the end of each calendar
              day, the average outstanding value of the asset-referenced token
              issued by an issuer never exceeds <b>5 million euros</b>, or
            </li>
            <li className="py-1">
              the public offering is addressed solely to{" "}
              <b>qualified investors</b> and the asset-referenced token can only
              be held by such qualified investors.
            </li>
          </ul>
          <p className="pt-2">
            In these two cases, only the general principles presented above
            apply (to act honestly, fairly and professionally; to communicate in
            a fair, clear and not misleading manner; to identify, prevent,
            manage and disclose conflicts of interest; to act in the best
            interests of the holders of the crypto-assets; and to treat all
            holders equally as a matter of principle). A white paper must still
            be prepared in such cases, but it does not have to be approved, but
            merely submitted to the supervisory authority. In this case,
            therefore, the same procedure applies as in the case of (normal)
            crypto-assets.
          </p>
          <p className="pt-2">
            If an asset-referenced token is used as a means of exchange within a
            single currency area for more than 1 million transactions per day
            with an average aggregate transaction value of more than 200 million
            euros, no further asset-referenced tokens of the same type may be
            issued. It must also be ensured that the transactions are brought
            below the thresholds. This provision is intended to prevent tokens
            from establishing themselves as a substitute for the euro. If one
            puts these amount limits in relation to established payment service
            providers such as VISA, it can be seen that the amounts are
            extremely small.
          </p>
          <p className="pt-2">
            Only credit institutions or e-money institutions are allowed to
            offer e-money tokens to the public. The obligation to publish a
            white paper also applies to e-money tokens, but unlike
            asset-referenced tokens, the white paper does not have to be not
            approved but is merely submitted to the supervisory authority, as is
            the case with other crypto-assets.
          </p>
          <p className="pt-2">
            E-money tokens must be redeemable at any time by their holders at
            par value. No fees may be retained for this purpose. Interest may
            likewise not be granted under MiCA for holding e-money tokens. The
            interest ban also applies to crypto-asset service providers.
          </p>
          <p className="pt-2">
            At least 30 % of the funds received by issuers in exchange for
            e-money tokens must be deposited in separate accounts with credit
            institutions; the remaining funds must be invested in highly liquid
            financial instruments with minimal market risk, credit risk, and
            concentration risk that are denominated in the same currency to
            which the e-money token relates. Issuers of e-money tokens must
            develop a recovery and redemption plan.
          </p>
        </section>
      </div>
    </section>
  );
}
