import React from "react";
import { Link, useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useMemo } from "react";

interface NavigationLinkProps {
  href: string;
  className?: React.HTMLProps<HTMLElement>["className"];
  activeClassName?: React.HTMLProps<HTMLElement>["className"];
  children: React.ReactNode;
  onClick?: () => void;
}

export default function NavigationLink(props: NavigationLinkProps) {
  const location = useLocation();
  const pathname = location.pathname;

  const isActive = useMemo(
    () => pathname === props.href || pathname.startsWith(`${props.href}/`),
    [pathname, props.href]
  );

  return (
    <Link
      to={props.href}
      className={
        isActive
          ? twMerge(props.className, twMerge(props.activeClassName))
          : props.className
      }
      onClick={props.onClick}
    >
      {props.children}
    </Link>
  );
}
