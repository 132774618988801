import { Suspense, lazy, useEffect, useState } from "react";
import { InfinitySpin } from "react-loader-spinner";

const SurveyComponent = lazy(() => import("../Survey"));

export default function QuestionnaireWhitepaper() {
  const [json, setJson] = useState<any | null>(null);

  useEffect(() => {
    const getJson = async () => {
      const json = (await import("../../../locale/whitepaperSurvey")).json;

      if (json) {
        setJson(json);
      }
    };

    getJson();
  });

  if (json) {
    return (
      <Suspense fallback={<InfinitySpin width="200" color="royalblue" />}>
        <SurveyComponent json={json} />
      </Suspense>
    );
  }

  return <InfinitySpin width="200" color="royalblue" />;
}
