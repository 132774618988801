import { copyTextToClipboard } from "./lib/util";
import { LinkIcon } from "@heroicons/react/24/outline";

interface AnchorButtonProps {
  href: string;
}

export default function AnchorButton(props: AnchorButtonProps) {
  return (
    <a
      href={props.href}
      className="inline-flex p-0 m-0 bg-none border-none"
      onClick={(e) => {
        copyTextToClipboard(e.currentTarget.href);
      }}
    >
      <LinkIcon className="w-4" />
    </a>
  );
}
