import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useMemo } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import HTMLReactParser, {
  Element,
  attributesToProps,
  domToReact,
} from "html-react-parser";
import { InfinitySpin } from "react-loader-spinner";
import dayjs from "dayjs";
import styles from "./InsightArticle.module.css";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

interface Article {
  Headline: string;
  Subtitle?: string;
  Content: string;
  "Short description": string;
  _created: number;
}

export default function InsightArticle() {
  const params = useParams();
  const { isLoading, data } = useQuery<Article[]>({
    queryKey: ["article", params && params.slug],
    queryFn: () =>
      axios
        .get(
          `${
            process.env.REACT_APP_GOMICA_CMS_API_URL
          }content/items/Articles?filter=${JSON.stringify({
            slug: params.slug,
          })}`,
          {
            headers: {
              "API-KEY": process.env.REACT_APP_GOMICA_CMS_API_KEY,
            },
          }
        )
        .then((res) => res.data),
    enabled: params && params.slug != null,
  });

  const article: Article | null = useMemo(() => {
    if (data && data.length > 0) {
      return data[0];
    }

    return null;
  }, [data]);

  return (
    <>
      {article && (
        <Helmet>
          <title>{article.Headline}</title>
          <meta name="description" content={article["Short description"]} />
        </Helmet>
      )}

      <Link
        to="/insights"
        className="mb-4 flex flex-row justify-start items-center gap-2 text-slate-600 dark:text-slate-400"
      >
        <ChevronLeftIcon className="h-4" /> Back to Insights
      </Link>

      {isLoading && <InfinitySpin width="200" color="royalblue" />}

      <h1 className="text-5xl text-svlaw-blue-light dark:text-svlaw-gold">
        {article?.Headline}
      </h1>
      {article?.Subtitle && (
        <h2 className="text-3xl text-svlaw-blue-dark dark:text-svlaw-gold/80 my-2">
          {article.Subtitle}
        </h2>
      )}

      <div
        className={classNames(
          "flex flex-col justify-start items-start gap-2 leading-7 mt-6 text-justify",
          styles["insight-article"]
        )}
      >
        {article?.Content &&
          HTMLReactParser(article.Content, {
            replace: (domNode) => {
              if (
                domNode instanceof Element &&
                domNode.attribs &&
                domNode.name &&
                domNode.name === "a"
              ) {
                const props = attributesToProps(domNode.attribs);

                return (
                  <a
                    {...props}
                    target="_blank"
                    className="text-svlaw-blue-light underline underline-offset-4 hover:text-svlaw-gold dark:hover:text-sky-500 dark:text-svlaw-gold transition-colors duration-300"
                  >
                    {domToReact(domNode.children)}
                  </a>
                );
              }
              return domNode;
            },
          })}
      </div>

      {article?._created && (
        <div className="text-slate-500 dark:text-slate-400 text-xs italic mt-8">
          Published on{" "}
          {dayjs.unix(article._created).format("dd., D.M.YYYY [at] HH:mm")}
        </div>
      )}
    </>
  );
}
