import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { HomeIcon } from "@heroicons/react/24/solid";
import MicaChapterNavigation from "../../MicaChapterNavigation";
import micaChapters from "../../..//MicaChapters.json";
import AnchorButton from "../../../AnchorButton";
import MobileMicaChapterNavigation from "../../MobileMicaChapterNavigation";

export default function InsiderDealingAndMarketManipulation() {
  return (
    <section className="px-4 lg:px-0">
      <div className="text-justify">
        {/* Chapter-Navigation */}
        <MicaChapterNavigation urls={micaChapters} />
        {/* Breadcrumbs */}
        <div className="flex flex-row gap-2 mb-8 rounded-md bg-zinc-50/90 dark:bg-slate-950/80 shadow-sm px-4 py-2 static md:sticky md:top-24 backdrop-blur-md border-2 border-zinc-100 dark:border-slate-800">
          <Link
            to="/mica"
            className="text-slate-400 font-bold inline-flex flex-row gap-1 items-center"
          >
            <HomeIcon className="text-slate-400 w-4" /> MiCA
          </Link>
          <ChevronRightIcon className="text-slate-600 w-4" />
          <span className="text-slate-600 font-bold dark:text-slate-50 hidden md:block">
            Insider dealing and market manipulation
          </span>
          <MobileMicaChapterNavigation urls={micaChapters}>
            <span className="text-slate-600 font-bold dark:text-slate-50">
              Insider dealing and market manipulation
            </span>
          </MobileMicaChapterNavigation>
        </div>

        <h4 className="text-md text-zinc-600 dark:text-zinc-400">
          Markets in Crypto-Assets Regulation (MiCA)
        </h4>
        <h1
          className="text-4xl text-svlaw-blue-dark mb-1 dark:text-svlaw-gold"
          id="insider-dealing"
        >
          Insider dealing and market manipulation{" "}
          <AnchorButton href="#insider-dealing" />
        </h1>

        <section>
          <h2
            className="text-3xl text-svlaw-blue-light mb-1 mt-7 dark:text-sky-200"
            id="general-remarks"
          >
            1. General remarks on the respective provisions{" "}
            <AnchorButton href="#general-remarks" />
          </h2>
          <p>
            MiCA contains an insider dealing and market manipulation regime that
            is similar to that for financial instruments. On the one hand, it
            recognizes the requirement to publicly disclose insider information,
            the prohibition of insider dealing, the prohibition of unlawful
            disclosure of insider information, the prohibition of market
            manipulation and the requirement to prevent and detect market abuse.
          </p>

          <p className="pt-2">
            These rules apply to all persons and all acts (and omissions) in
            connection with crypto-assets admitted to trading or for which
            admission to trading has been requested, regardless of whether they
            take place in the Union or in third countries. This extraterritorial
            application is noteworthy.
          </p>

          <p className="pt-2">
            The insider dealing rules and market abuse provisions are also not
            limited to trading platforms, but apply universally. All trades are
            covered, all orders and actions, regardless of whether such a trade,
            order or action was made on a trading platform or not. The business
            model known as MEV (miner/maximum extractable value) will therefore
            soon be subject to a tight set of rules.
          </p>
        </section>
        <section>
          <h2
            className="text-3xl text-svlaw-blue-light mb-1 mt-7 dark:text-sky-200"
            id="requirement-to-disclose-inside-information"
          >
            2. Requirement to publicly disclose inside information{" "}
            <AnchorButton href="#requirement-to-disclose-inside-information" />
          </h2>
          <p>
            Under MiCA, inside information includes any information of a precise
            nature, which has not been made public, relating, directly or
            indirectly, to one or more issuers, offerors or persons seeking
            admission to trading, or to one or more crypto-assets, and which, if
            it were made public, would likely have a significant effect on the
            prices of those crypto-assets or on the price of a related
            crypto-asset.
          </p>
          <p className="pt-2">
            Issuers, offerors and persons applying for admission to trading must
            disclose inside information to the public without delay and in a
            manner that allows the public to access it quickly and make a
            complete, correct and timely assessment. This must not be linked to
            the marketing of their activity. The publication must be made on the
            website and must be available there for at least five years. Under
            certain circumstances, the publication of inside information may be
            postponed.
          </p>
        </section>
        <section>
          <h2
            className="text-3xl text-svlaw-blue-light mb-1 mt-7 dark:text-sky-200"
            id="prohibition-of-insider-dealing"
          >
            3. Prohibition of insider dealing{" "}
            <AnchorButton href="#prohibition-of-insider-dealing" />
          </h2>
          <p>
            Under MiCA, insider dealing arises where a person possesses inside
            information and uses that information by acquiring or disposing of,
            for its own account or for the account of a third party, directly or
            indirectly, crypto-assets to which that information relates. No
            person shall engage or attempt to engage in insider dealing or use
            inside information about crypto-assets to acquire, or dispose of,
            those crypto-assets, directly or indirectly, whether for that
            person&apos;s own account or for the account of a third party. In
            addition, no person shall recommend that another person engage in
            insider dealing or induce another person to engage in insider
            dealing.
          </p>
          <p className="pt-2">
            However, the prohibition of insider dealing does not apply
            generally, but only to persons who have inside information by virtue
            of their role (administrative, management, supervisory body, owner,
            or certain other roles), or persons who have obtained it as a result
            of criminal acts, or to all other persons if they know or should
            have known that it is inside information.
          </p>
        </section>
        <section>
          <h2
            className="text-3xl text-svlaw-blue-light mb-1 mt-7 dark:text-sky-200"
            id="prohibition-of-unlawful-disclosure"
          >
            4. Prohibition of unlawful disclosure of insider information{" "}
            <AnchorButton href="#prohibition-of-unlawful-disclosure" />
          </h2>
          <p>
            No person in possession of inside information shall unlawfully
            disclose inside information to any other person, except where such
            disclosure is made in the normal exercise of an employment, a
            profession or duties. Instead, the requirement to disclose applies
            to the legal entities mentioned above.
          </p>
        </section>
        <section>
          <h2
            className="text-3xl text-svlaw-blue-light mb-1 mt-7 dark:text-sky-200"
            id="prohibition-of-market-manipulation"
          >
            5. Prohibition of market manipulation{" "}
            <AnchorButton href="#prohibition-of-market-manipulation" />
          </h2>
          <p>
            No person shall engage in or attempt to engage in market
            manipulation. In this context, market manipulation comprises any of
            the following activities:
          </p>
          <ul className="list-disc list-outside ml-4">
            <li className="py-1">
              unless carried out for legitimate reasons, entering into a
              transaction, placing an order to trade or engaging in any other
              behavior which:
              <ul className="list-[circle] list-outside pl-8">
                <li>
                  gives, or is likely to give, false or misleading signals as to
                  the supply of, demand for, or price of, a crypto-asset;
                </li>
                <li>
                  secures, or is likely to secure, the price of one or several
                  crypto-assets at an abnormal or artificial level.
                </li>
              </ul>
            </li>
            <li className="py-1">
              entering into a transaction, placing an order to trade or any
              other activity or behavior which affects or is likely to affect
              the price of one or several crypto-assets, while employing a
              fictitious device or any other form of deception or contrivance;
              or
            </li>
            <li className="py-1">
              disseminating information through the media, including the
              internet, or by any other means, which gives, or is likely to
              give, false or misleading signals as to the supply of, demand for,
              or price of one or several crypto-assets, or secures or is likely
              to secure, the price of one or several crypto-assets, at an
              abnormal or artificial level, including the dissemination of
              rumors, where the person who engaged in the dissemination knew, or
              ought to have known, that the information was false or misleading.
            </li>
          </ul>
          <p className="pt-1">
            In addition, MiCA defines other acts as market manipulation that do
            not involve false or misleading information or signals.
          </p>
        </section>
        <section>
          <h2
            className="text-3xl text-svlaw-blue-light mb-1 mt-7 dark:text-sky-200"
            id="requirement-to-prevent-market-abuse"
          >
            6. Requirement to prevent and detect market abuse{" "}
            <AnchorButton href="#requirement-to-prevent-market-abuse" />
          </h2>
          <p>
            MiCA requires that persons who professionally arrange or execute
            transactions in crypto-assets have in place effective arrangements,
            systems and procedures to prevent and detect market abuse. These
            persons are subject to reporting obligations in the event of
            suspected market abuse.
          </p>
        </section>
      </div>
    </section>
  );
}
