import React, { useState } from "react";
import { Drawer } from "vaul";
import NavigationLink from "./NavigationLink";

interface MobileMicaChapterNavigationProps {
  children: React.ReactNode;
  urls: {
    title: string;
    href: string;
  }[];
}

export default function MobileMicaChapterNavigation(
  props: MobileMicaChapterNavigationProps
) {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  return (
    <div className="inline-block md:hidden">
      <Drawer.Root onOpenChange={setMenuIsOpen} open={menuIsOpen}>
        <Drawer.Trigger className="text-left">{props.children}</Drawer.Trigger>
        <Drawer.Portal>
          <Drawer.Overlay className="fixed inset-0 bg-black/40 dark:bg-black/50" />
          <Drawer.Content
            className="bg-zinc-100 dark:bg-zinc-800 flex flex-col items-start justify-start gap-8 rounded-t-[18px] fixed bottom-0 left-0 right-0 p-8"
            style={{ height: "calc(100vh - 220px)" }}
          >
            <h1 className="text-3xl text-svlaw-blue-dark dark:text-slate-50 border-b-2 dark:border-b-slate-500 pb-2 w-full">
              Chapters
            </h1>
            {props.urls.map((u) => (
              <NavigationLink
                key={u.href}
                href={u.href}
                className="text-xl font-medium duration-500 text-svlaw-blue-light dark:text-slate-300"
                activeClassName="text-svlaw-gold dark:text-svlaw-gold font-bold"
                onClick={() => {
                  setMenuIsOpen(false);
                }}
              >
                {u.title}
              </NavigationLink>
            ))}
          </Drawer.Content>
        </Drawer.Portal>
      </Drawer.Root>
    </div>
  );
}
