import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import dayjs from "dayjs";
import { useMemo } from "react";
import { InfinitySpin } from "react-loader-spinner";

interface Resource {
  Title: string;
  Subtitle?: string;
  URL: string;
  _id: string;
  _modified: number;
  _created: number;
  OfficialPublishedDate: string;
  category?: string;
}

export default function Resources() {
  const query = useQuery<Resource[]>({
    queryKey: ["resources"],
    queryFn: () =>
      axios
        .get(
          `${process.env.REACT_APP_GOMICA_CMS_API_URL}content/items/Resources`,
          {
            headers: {
              "API-KEY": process.env.REACT_APP_GOMICA_CMS_API_KEY,
            },
          }
        )
        .then((res) => res.data),
  });

  const categories = useMemo(() => {
    if (query.data && query.data.length > 0) {
      return [
        ...query.data.reduce((categories, resource) => {
          if (resource.category) {
            if (categories.indexOf(resource.category) >= 0) return categories;
            return [...categories, resource.category];
          }

          return categories;
        }, [] as string[]),
        "General",
      ];
    }

    return [];
  }, [query.data]);

  console.log("categories", categories);

  return (
    <>
      <h1 className="text-4xl text-svlaw-blue-light dark:text-svlaw-gold mb-4">
        Resources
      </h1>

      {query.isLoading && <InfinitySpin width="200" color="royalblue" />}

      {query.data &&
        categories &&
        categories
          .filter(
            (c) =>
              query.data.filter(
                (r) => r.category === c || (c === "General" && !r.category)
              ).length > 0
          )
          .map((c) => (
            <div className="overflow-hidden shadow-sm dark:shadow-none border-2 border-slate-200 bg-slate-50/60 dark:border-slate-600 dark:bg-slate-700/30 rounded-xl mb-7">
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="px-4 py-2 text-left bg-slate-200 dark:bg-slate-700">
                      {c}
                    </th>
                    <th className="pr-2 py-2 text-right bg-slate-200 dark:bg-slate-700">
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {query.data
                    .filter(
                      (r) =>
                        r.category === c || (!r.category && c === "General")
                    )
                    .map((resource: Resource) => (
                      <tr className="even:bg-slate-300/20" key={resource._id}>
                        <td className="p-4 gap-x-8 flex flex-row items-center">
                          <a
                            rel="noreferrer"
                            href={resource.URL}
                            target="_blank"
                            title={resource.Title}
                            className="font-bold text-svlaw-blue-light dark:text-svlaw-gold dark:hover:text-white hover:text-svlaw-gold transition-colors duration-500"
                          >
                            <span className="font-bold">{resource.Title}</span>
                            {resource.Subtitle && (
                              <span className="text-xs font-thin">
                                {resource.Subtitle}
                              </span>
                            )}
                          </a>
                        </td>
                        <td width={140} className="text-right pr-2">
                          <span className="text-xs italic text-slate-500">
                            {resource.OfficialPublishedDate
                              ? dayjs(resource.OfficialPublishedDate).format(
                                  "D MMMM YYYY"
                                )
                              : dayjs
                                  .unix(resource._modified)
                                  .format("D MMMM YYYY")}
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ))}
    </>
  );
}
