import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { HomeIcon } from "@heroicons/react/24/solid";
import MicaChapterNavigation from "../../MicaChapterNavigation";
import micaChapters from "../../..//MicaChapters.json";
import AnchorButton from "../../../AnchorButton";
import MobileMicaChapterNavigation from "../../MobileMicaChapterNavigation";

export default function CryptoAssetServices() {
  return (
    <section className="px-4 lg:px-0">
      <div className="text-justify">
        {/* Chapter-Navigation */}
        <MicaChapterNavigation urls={micaChapters} />
        {/* Breadcrumbs */}
        <div className="flex flex-row gap-2 mb-8 rounded-md bg-zinc-50/90 dark:bg-slate-950/80 shadow-sm px-4 py-2 static md:sticky md:top-24 backdrop-blur-md border-2 border-zinc-100 dark:border-slate-800">
          <Link
            to="/mica"
            className="text-slate-400 font-bold inline-flex flex-row gap-1 items-center"
          >
            <HomeIcon className="text-slate-400 w-4" /> MiCA
          </Link>
          <ChevronRightIcon className="text-slate-600 w-4" />
          <span className="text-slate-600 font-bold dark:text-slate-50 hidden md:block">
            Crypto-asset services
          </span>
          <MobileMicaChapterNavigation urls={micaChapters}>
            <span className="text-slate-600 font-bold dark:text-slate-50">
              Crypto-asset services
            </span>
          </MobileMicaChapterNavigation>
        </div>

        <h4 className="text-md text-zinc-600 dark:text-zinc-400">
          Markets in Crypto-Assets Regulation (MiCA)
        </h4>
        <h1
          className="text-4xl text-svlaw-blue-dark mb-1 dark:text-svlaw-gold"
          id="crypto-asset-services"
        >
          Crypto-asset services <AnchorButton href="#crypto-asset-services" />
        </h1>

        <section>
          <h2
            className="text-3xl text-svlaw-blue-light mb-1 mt-7 dark:text-sky-200"
            id="service-providers"
          >
            1. Crypto-asset service providers{" "}
            <AnchorButton href="#service-providers" />
          </h2>
          <p>
            MiCA distinguishes between two groups of crypto-asset service
            providers: (1) companies that acquire a license under MiCA, or (2)
            companies that already hold a license to provide (traditional)
            financial services.
          </p>

          <p className="pt-2">
            All crypto-asset service providers must have their registered office
            in an EU member state where they conduct at least part of their
            service business. They must also have their place of effective
            management in the Union, and at least one of the managers must be an
            EU resident. Providers may provide their services throughout the
            Union; this may be done either under the freedom of establishment
            (e.g. through a branch), or under the freedom to provide services
            (i.e. without establishment).
          </p>
          <p className="pt-2">
            For companies that already hold a financial services license, MiCA
            outlines which services can be provided by which entities:
          </p>
          <ul className="list-disc list-outside ml-4">
            <li className="py-1">
              <b>Credit institutions</b> are generally allowed to provide all
              services.
            </li>
            <li className="py-1">
              <b>Central securities depositories</b> are allowed to hold and
              administer crypto-assets for customers.
            </li>
            <li className="py-1">
              <b>Investment firms</b> may provide services equivalent to those
              activities for which they are licensed in connection with
              financial instruments, i.e. custody and administration, operation
              of a trading platform, exchange for funds or other crypto-assets,
              execution of orders for clients, placement, reception and
              transmission of orders, advisory services or portfolio management.
            </li>
            <li className="py-1">
              <b>Electronic money institutions</b> may issue e-money tokens and
              provide custody and administration on behalf of customers as well
              as transfer services for these electronic money tokens.
            </li>
            <li className="py-1">
              A <b>UCITS management company</b> or an{" "}
              <b>alternative investment fund manager</b> may provide such
              services that are equivalent to the individual management of
              individual portfolios and the ancillary services for which a
              license exists with regard to fund units, i.e., e.g., acceptance
              and transmission of orders, advisory services, or portfolio
              management.
            </li>
            <li className="py-1">
              A <b>market operator</b> may operate a trading platform for
              crypto-assets.
            </li>
          </ul>

          <p className="pt-2">
            These companies may provide the respective crypto-asset services
            after following a certain procedure. First, the companies must
            notify the competent authority at least 40 working days in advance
            and provide certain information, which is further defined by MiCA.
            This includes, among other things, a business plan and various
            descriptions of internal operations and processes. The authority
            will review the information within 20 working days and may request
            additional information as necessary. The company may provide the
            services if the requested information has been fully provided to the
            authority.
          </p>
          <p className="pt-2">
            However, this simplified procedure is only available to the
            above-mentioned companies that are already supervised. All other
            companies must submit an application for authorization to the
            competent supervisory authority. This is the regulator of the member
            state in which the company has its registered office, e.g. the FMA
            for companies based in Austria, or BaFin for companies based in
            Germany.
          </p>
          <p className="pt-2">
            MiCA contains detailed information on the application procedure and
            the necessary documents to be submitted. After examining the
            application, the authority can issue improvement orders and request
            additional information. MiCA provides authorities multiple grounds
            to reject a license application. This includes, for example,
            concerns about the personal reliability of persons involved or
            deficient internal control procedures.
          </p>
          <p className="pt-2">
            Overall, it is expected that the approval process will become
            significantly more difficult compared to the legal situation prior
            to MiCA. This will create significant barriers to entry. And since a
            license will allow services to be offered throughout the Union, it
            is to be expected that individual member states will seek to
            establish themselves as hotspots for crypto-asset service providers.
            France is already noticeably positioning itself as a potential
            center of Europe. We also expect a pro-crypto commitment from the
            Baltic states or Malta. For crypto-asset service providers, the
            advantage of concentrating on just a few member states is that
            regulators can gain more experience and respond better to the
            specifics of the industry.
          </p>
        </section>
        <section>
          <h2
            className="text-3xl text-svlaw-blue-light mb-1 mt-7 dark:text-sky-200"
            id="general-requirements"
          >
            2. General requirements for all service providers{" "}
            <AnchorButton href="#general-requirements" />
          </h2>
          <p>
            All crypto-asset service providers are subject to certain general
            obligations. These include, on the one hand, the duty to act
            honestly, fairly and professionally in the best interests of the
            customer, which also applies to offerors of crypto-assets. The duty
            to provide fair, clear and not misleading marketing communications
            and to warn of risks also apply to crypto-asset service providers as
            well as to offerors of crypto-assets.
          </p>
          <p className="pt-2">
            MiCA also provides certain prudential requirements, e.g., minimum
            capital requirements, as well as governance arrangements. MiCA
            further requires as a general principle that all service providers
            store crypto-assets and funds securely, in a way that in case of
            insolvency of the service provider the crypto-assets and funds of
            the clients are protected. For Austria, this means rights for
            segregation and preferential rights in case of insolvency must
            exist.
          </p>
          <p className="pt-2">
            All crypto-asset service providers must also have a functioning
            complaint management system and adequate procedures to identify,
            prevent, manage and disclose conflicts of interest. Outsourcing may
            only be undertaken in accordance with MiCA requirements, and each
            service provider must have a plan in place to properly manage its
            business should the outsourcing provider cease operations.
          </p>
          <p className="pt-2">
            In addition to these general obligations, MiCA imposes a number of
            specific obligations, each of which affects providers of certain
            services.
          </p>
        </section>
        <section>
          <h2
            className="text-3xl text-svlaw-blue-light mb-1 mt-7 dark:text-sky-200"
            id="special-provisions"
          >
            3. Special provisions for individual services{" "}
            <AnchorButton href="#special-provisions" />
          </h2>
          <h3
            className="text-2xl text-svlaw-blue-dark mb-1 mt-5"
            id="custody-and-administration"
          >
            a) Custody and administration of crypto-assets{" "}
            <AnchorButton href="#custody-and-administration" />
          </h3>
          <p>
            Custody and administration of crypto-assets means the safekeeping or
            controlling, on behalf of clients, of crypto-assets or of the means
            of access to such crypto-assets, where applicable in the form of
            private cryptographic keys. This primarily refers to the model known
            on the market as custodial wallet, in which a person other than the
            owner holds the private keys to certain crypto-assets.
          </p>
          <p className="pt-2">
            MiCA contains specific requirements for companies wishing to provide
            this service to customers. First of all, all essential contractual
            duties and responsibilities must be clearly specified. Service
            provider must also maintain a register for all clients, in which,
            for example, the ownership positions and other rights of all clients
            are to be recorded. If a crypto-asset grants its holder rights
            against the issuer, the service provider must enable its customer to
            easily exercise these rights. In general, the customer is entitled
            to all rights, even in the case of a hard fork with regard to the
            newly created crypto-assets.
          </p>
          <p className="pt-2">
            MiCA provides for a liability provision for custodians in case of
            loss of crypto-assets. The custodian is liable to its clients for
            all losses suffered by them if the respective event is attributable
            to the service provider. The liability is limited to the market
            value of the lost crypto-assets at the time of the loss.
          </p>
          <h3
            className="text-2xl text-svlaw-blue-dark mb-1 mt-5"
            id="operation-of-a-trading-platform"
          >
            b) Operation of a trading platform for crypto-assets{" "}
            <AnchorButton href="#operation-of-a-trading-platform" />
          </h3>
          <p>
            Under MiCA, the operation of a trading platform for crypto-assets
            means the management of one or more multilateral systems, which
            bring together or facilitate the bringing together of multiple
            third-party purchasing and selling interests in crypto-assets, in
            the system and in accordance with its rules, in a way that results
            in a contract, either by exchanging crypto-assets for funds or by
            the exchange of crypto-assets for other crypto-assets. MiCA also
            contains specific requirements for entities that wish to provide
            this service to clients.
          </p>
          <p className="pt-2">
            MiCA requires companies to lay down, maintain and implement clear
            and transparent operating rules for the trading platform. Service
            providers are required to have policies on the approval procedures
            for listing new crypto-assets; this includes exclusion criteria as
            well as listing fees. Furthermore, rules on participation in trading
            activities shall be established, fair and orderly trading shall be
            ensured, and objective criteria for the execution of orders shall be
            established. Rules on securing trading, suspension of trading and
            settlement of orders shall be included.
          </p>
          <p className="pt-2">
            All crypto-assets that are to be admitted to trading must be
            evaluated in advance, including with regard to the reliability of
            the technical solutions used and potential links to illegal
            activities. The issuer&apos;s experience, track record and
            reputation must also be taken into account. Anonymous crypto-assets
            may not be admitted to trading unless the service provider can
            verify the transaction history.
          </p>
          <p className="pt-2">
            The service provider may not become active on its own trading
            platform, i.e. may not use the trading platform on its own account.
            The platform must also be resilient, have sufficient capacity to
            function at peak times, and also be able to detect market abuse. In
            addition, there are a number of other obligations. All trading
            prices must be published in real time (for a fee) and with a
            fifteen-minute delay (without a fee). All data must also be retained
            for five years.
          </p>

          <h3
            className="text-2xl text-svlaw-blue-dark mb-1 mt-5"
            id="exchange-of-crypto-assets"
          >
            c) Exchange of crypto-assets for funds or other crypto-assets{" "}
            <AnchorButton href="#exchange-of-crypto-assets" />
          </h3>
          <p>
            Exchange of crypto-assets for funds means the conclusion of purchase
            or sale contracts concerning crypto-assets with clients for funds by
            using proprietary capital. The same applies mutatis mutandis to the
            exchange of crypto-assets for other crypto-assets. MiCA contains
            specific requirements for companies that wish to provide this
            service to clients.
          </p>
          <p className="pt-2">
            The requirements are relatively manageable compared to other
            services. Companies must ensure that they pursue a
            non-discriminatory business policy, publish the method used to
            calculate the price offered in each case, actually trade at this
            price, and publish information about the transactions they have
            concluded.
          </p>

          <h3
            className="text-2xl text-svlaw-blue-dark mb-1 mt-5"
            id="execution-of-orders"
          >
            d) Execution of orders on crypto-assets for clients{" "}
            <AnchorButton href="#execution-of-orders" />
          </h3>
          <p>
            Execution of orders on crypto-assets means the conclusion of
            agreements, on behalf of clients, to purchase or sell one or more
            crypto-assets or the subscription on behalf of clients for one or
            more crypto-assets, and includes the conclusion of contracts to sell
            crypto-assets at the moment of their offer to the public or
            admission to trading. MiCA contains specific requirements for
            entities that wish to provide this service to clients.
          </p>
          <p className="pt-2">
            The primary obligation is to achieve the best possible result for
            the customer when executing orders (e.g., market orders). This does
            not apply only if the customer gives specific instructions (i.e.
            limit orders). The execution policy must be disclosed; it must in
            principle be implemented swiftly and in good faith and prevent
            market abuse and the use of insider information.
          </p>

          <h3
            className="text-2xl text-svlaw-blue-dark mb-1 mt-5"
            id="placing-of-crypto-assets"
          >
            e) Placing of crypto-assets{" "}
            <AnchorButton href="#placing-of-crypto-assets" />
          </h3>
          <p>
            Placing of crypto-assets means the marketing, on behalf of or for
            the account of the offeror or a party related to the offeror, of
            crypto-assets to purchasers. The essential duty of the service
            provider is to specify to its client, i.e. the issuer of a
            crypto-asset, exactly what tasks will be undertaken. This includes
            specifying guarantees or minimum quantities, amount of transaction
            fees, expected schedule, procedures and prices. Prior to a
            placement, the issuer&apos;s consent must be obtained in order to be
            allowed to use the white paper.
          </p>

          <h3
            className="text-2xl text-svlaw-blue-dark mb-1 mt-5"
            id="reception-and-transmission"
          >
            f) Reception and transmission of orders{" "}
            <AnchorButton href="#reception-and-transmission" />
          </h3>
          <p>
            Reception and transmission of orders for crypto-assets on behalf of
            clients means the reception from a person of an order to purchase or
            sell one or more crypto-assets or to subscribe for one or more
            crypto-assets and the transmission of that order to a third party
            for execution. The specific provisions contained in MiCA are
            manageable. The service provider must provide procedures for how
            client orders are to be routed. They may not demand remuneration or
            other benefits from trading platforms for the forwarding of client
            orders.
          </p>

          <h3
            className="text-2xl text-svlaw-blue-dark mb-1 mt-5"
            id="providing-advice"
          >
            g) Providing advice on crypto-assets and portfolio management{" "}
            <AnchorButton href="#providing-advice" />
          </h3>
          <p>
            Providing advice on crypto-assets means offering, giving or agreeing
            to give personalized recommendations to a client, either at the
            client&apos;s request or on the initiative of the crypto-asset
            service provider providing the advice, in respect of one or more
            transactions relating to crypto-assets, or the use of crypto-asset
            services. Portfolio management means managing portfolios in
            accordance with mandates given by clients on a discretionary
            client-by-client basis where such portfolios include one or more
            crypto-assets. MiCA includes specific guidance for entities seeking
            to provide these services to clients.
          </p>

          <p className="pt-2">
            Among the core obligations is to consider whether crypto-assets are
            suitable for the client. Just as in the area of financial
            instruments, the knowledge and experience with investments in
            crypto-assets, their investment objectives, risk tolerance and
            financial situation must be taken into account, including the
            customer&apos;s ability to bear losses.
          </p>
          <p className="pt-2">
            Anyone providing advice on crypto-assets must inform their clients
            whether the advice is provided independently, is based on an
            extensive or more limited analysis of various crypto-assets, or is
            limited to crypto-assets issued by entities that have close ties to
            the service provider or otherwise have a legal or economic
            relationship.
          </p>
          <p className="pt-2">
            If the advice is provided on an independent basis, it must be
            sufficiently diversified. In this case, the advisor may also not
            accept commissions or other benefits from third parties. However,
            even in the case of non-independent advice, advantages may only be
            accepted if they are intended to improve the quality of the service
            and do not impair the service provider in the fulfillment of his
            duty to act honestly, fairly and professionally and in the best
            interests of the client.
          </p>
          <p className="pt-2">
            In addition, these service providers are subject to certain other
            obligations, such as the obligation to identify risks or not to
            recommend crypto-assets or not consider them in portfolio management
            if they do not fit the customer profile. A record of the advice must
            be prepared and handed over. In the case of portfolio management,
            the measures taken and the development of the portfolio must be
            reported to clients on an ongoing basis.
          </p>

          <h3
            className="text-2xl text-svlaw-blue-dark mb-1 mt-5"
            id="transfer-services"
          >
            h) Crypto-asset transfer services{" "}
            <AnchorButton href="#transfer-services" />
          </h3>
          <p>
            Providing transfer services means providing services of transfer, on
            behalf of a natural or legal person, of crypto-assets from one
            distributed ledger address or account to another. Thus, only
            book-entry transfers that are not settled on the blockchain are also
            covered. The requirements under MiCA are quite straightforward and
            are limited to the requirement for a clear contractual basis with
            specific content.
          </p>
        </section>
      </div>
    </section>
  );
}
