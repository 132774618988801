import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

export default function MiCAOverview() {
  return (
    <>
      <h1 className="text-4xl text-svlaw-blue-light dark:text-svlaw-gold mb-4">
        Regulation on Markets in Crypto-Assets (MiCA)
      </h1>
      <div className="flex flex-col gap-4 mt-0 md:mt-8">
        <p className="text-justify">
          MiCA introduces a new legal framework across the Union for the public
          offering of crypto-assets and the provision of crypto-asset services.
          The Regulation also includes prohibitions on market manipulation and
          insider dealing.
        </p>
        <p className="text-justify">
          This five-part series highlights the new rules applicable to the
          crypto industry and explains how they will affect the existing players
          and new entrants going forward.
        </p>

        <p className="text-justify pb-0 mb-0">
          The structure of the series follows the structure of MiCA itself:
        </p>
        <ul className="list-outside">
          <li className="pb-2 text-justify">
            <h4 className="font-bold text-lg text-svlaw-blue-light dark:text-sky-300">
              Basic principles
            </h4>
            <p className="text-sm">
              First, we will examine the scope of MiCA. We will identify the
              persons and types of businesses MiCA applies to. We will also
              discuss the central concept of <i>crypto-asset</i> and shed light
              on the types of cryptocurrencies it covers. We will address the
              main exceptions to scope, examining when MiCA should not apply. We
              will also discuss what new crypto-assets are created by MiCA and
              what distinguishes each of these crypto-assets from one another.
            </p>
            <div className="mt-1 mb-2">
              <Link
                target="_self"
                to="/mica/basic-principles"
                className="group text-[9pt] px-3 py-1 rounded-md bg-svlaw-blue-light text-zinc-50 transition-all duration-300 hover:bg-svlaw-gold font-bold inline-flex flex-row hover:scale-105"
              >
                More on{" "}
                <span className="mx-1 font-extrabold">Basic Principles</span>
                <ArrowLongRightIcon className="w-4 group-hover:ml-2 transition-all" />
              </Link>
            </div>
          </li>
          <li className="pb-2 text-justify">
            <h4 className="font-bold text-lg text-svlaw-blue-light dark:text-sky-300">
              Public offer of crypto-assets
            </h4>
            <p className="text-sm">
              We will present the general regulations that apply to public
              offerings of crypto-assets. We will then shed light on provisions
              regarding the crypto-asset white paper, its structure, who has to
              create it and how it has to be published. We explain the
              principles regarding the right of withdrawal of a buyer of
              crypto-assets and the provisions regarding liability for a
              defective white paper. Last, we present specifics related to the
              public offering of asset-referenced tokens and e-money tokens.
            </p>
            <div className="mt-1 mb-2">
              <Link
                target="_self"
                to="/mica/public-offer-of-crypto-assets"
                className="group text-[9pt] px-3 py-1 rounded-md bg-svlaw-blue-light text-zinc-50 transition-all duration-300 hover:bg-svlaw-gold font-bold inline-flex flex-row hover:scale-105"
              >
                More on{" "}
                <span className="mx-1 font-extrabold">
                  Public offer of crypto assets
                </span>
                <ArrowLongRightIcon className="w-4 group-hover:ml-2 transition-all" />
              </Link>
            </div>
          </li>
          <li className="pb-2 text-justify">
            <h4 className="font-bold text-lg text-svlaw-blue-light dark:text-sky-300">
              Crypto-asset services
            </h4>
            <p className="text-sm">
              The third part is devoted to crypto-asset service providers and
              the services they provide. We will present the general
              requirements for service providers and discuss the specifics of
              each service, namely custody and administration, operation of a
              trading platform, exchange of crypto-assets for funds or other
              crypto-assets, execution of orders, placing, reception and
              transmission of orders, advisory services, portfolio management,
              and transfer services.
            </p>
            <div className="mt-1 mb-2">
              <Link
                target="_self"
                to="/mica/crypto-asset-services"
                className="group text-[9pt] px-3 py-1 rounded-md bg-svlaw-blue-light text-zinc-50 transition-all duration-300 hover:bg-svlaw-gold font-bold inline-flex flex-row hover:scale-105"
              >
                More on{" "}
                <span className="mx-1 font-extrabold">
                  Crypto-asset services
                </span>
                <ArrowLongRightIcon className="w-4 group-hover:ml-2 transition-all" />
              </Link>
            </div>
          </li>
          <li className="pb-2 text-justify">
            <h4 className="font-bold text-lg text-svlaw-blue-light dark:text-sky-300">
              Insider dealing and market manipulation
            </h4>
            <p className="text-sm">
              The fourth part will cover MiCA&apos;s provisions on insider
              dealing and market manipulation. We will examine what inside
              information is, how to deal with it, what market abuse is with
              regard to crypto-assets, and which markets are covered by the
              market abuse provisions.
            </p>
            <div className="mt-1 mb-2">
              <Link
                target="_self"
                to="/mica/insider-dealing-and-market-manipulation"
                className="group text-[9pt] px-3 py-1 rounded-md bg-svlaw-blue-light text-zinc-50 transition-all duration-300 hover:bg-svlaw-gold font-bold inline-flex flex-row hover:scale-105"
              >
                More on{" "}
                <span className="mx-1 font-extrabold">
                  Insider dealing and market manipulation
                </span>
                <ArrowLongRightIcon className="w-4 group-hover:ml-2 transition-all" />
              </Link>
            </div>
          </li>
          <li className="pb-2 text-justify">
            <h4 className="font-bold text-lg text-svlaw-blue-light dark:text-sky-300">
              Unregulated business models
            </h4>
            <p className="text-sm">
              Finally, in the last part, we will shed light on those crypto
              business models for which MiCA does not provide any rules, i.e.
              which will remain unregulated in the future.
            </p>
            <div className="mt-1 mb-2">
              <Link
                target="_self"
                to="/mica/unregulated-business-models"
                className="group text-[9pt] px-3 py-1 rounded-md bg-svlaw-blue-light text-zinc-50 transition-all duration-300 hover:bg-svlaw-gold font-bold inline-flex flex-row hover:scale-105"
              >
                More on{" "}
                <span className="mx-1 font-extrabold">
                  Unregulated business models
                </span>
                <ArrowLongRightIcon className="w-4 group-hover:ml-2 transition-all" />
              </Link>
            </div>
          </li>
        </ul>
        <p className="text-justify">
          This series aims to give a practical overview of MiCA. In selecting
          the main topics, we have relied on our many years of experience in
          advising crypto companies. By necessity, the series does not cover
          every single aspect of MiCA. The series therefore does not claim to be
          exhaustive. It cannot replace legal advice in individual cases.
        </p>
      </div>
    </>
  );
}
