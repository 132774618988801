import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { HomeIcon } from "@heroicons/react/24/solid";
import MicaChapterNavigation from "../../MicaChapterNavigation";
import micaChapters from "../../..//MicaChapters.json";
import AnchorButton from "../../../AnchorButton";
import MobileMicaChapterNavigation from "../../MobileMicaChapterNavigation";

export default function UnregulatedBusinessModels() {
  return (
    <section className="px-4 lg:px-0">
      <div className="text-justify">
        {/* Chapter-Navigation */}
        <MicaChapterNavigation urls={micaChapters} />
        {/* Breadcrumbs */}
        <div className="flex flex-row gap-2 mb-8 rounded-md bg-zinc-50/90 dark:bg-slate-950/80 shadow-sm px-4 py-2 static md:sticky md:top-24 backdrop-blur-md border-2 border-zinc-100 dark:border-slate-800">
          <Link
            to="/mica"
            className="text-slate-400 font-bold inline-flex flex-row gap-1 items-center"
          >
            <HomeIcon className="text-slate-400 w-4" /> MiCA
          </Link>
          <ChevronRightIcon className="text-slate-600 w-4" />
          <span className="text-slate-600 font-bold dark:text-slate-50 hidden md:block">
            Unregulated crypto business models
          </span>
          <MobileMicaChapterNavigation urls={micaChapters}>
            <span className="text-slate-600 font-bold dark:text-slate-50">
              Unregulated crypto business models
            </span>
          </MobileMicaChapterNavigation>
        </div>

        <h4 className="text-md text-zinc-600 dark:text-zinc-400">
          Markets in Crypto-Assets Regulation (MiCA)
        </h4>
        <h1
          className="text-4xl text-svlaw-blue-dark mb-1 dark:text-svlaw-gold"
          id="unregulated-crypto-business-models"
        >
          Unregulated crypto business models{" "}
          <AnchorButton href="#unregulated-crypto-business-models" />
        </h1>

        <section>
          <h2
            className="text-3xl text-svlaw-blue-light mb-1 mt-7 dark:text-sky-200"
            id="business-models-outside-the-scope-of-mica"
          >
            1. Business models outside the scope of MiCA{" "}
            <AnchorButton href="#business-models-outside-the-scope-of-mica" />
          </h2>
          <p>
            MiCA covers a large part of the business activities performed by
            companies active in the crypto industry. Certain other activities
            were already covered under European financial regulations prior to
            MiCA (e.g., tokenized financial instruments). However, there are
            still several business areas that will remain unregulated even after
            MiCA enters into force. These include, for example:
          </p>

          <ul className="list-disc list-outside ml-4">
            <li className="py-1">
              Offering <b>self-hosted or unhosted wallets</b>, either in the
              form of wallet software or a paper wallet, as long as no
              crypto-assets are sold or transferred with the respective wallets.
            </li>
            <li className="py-1">
              Infrastructure operators, i.e. the operation of{" "}
              <b>mining facilities or validators</b> (depending on their
              structure). In this context, however, we refer to the legal
              opinion of the Austrian FMA, according to which the operation of
              mining facilities or validators may constitute an alternative
              investment fund under certain circumstances.
            </li>
            <li className="py-1">
              Truly <b>decentralized financial applications (DeFi)</b>, i.e.,
              those software solutions in the form of smart contracts where no
              contractual relationships are established or executed.
            </li>
            <li className="py-1">
              Genuine <b>non-fungible tokens</b>, i.e., those in which an
              individualized and non-fungible asset is linked to the token, such
              as rights to use works of art.
            </li>
          </ul>
          <p className="pt-2">
            It remains to be seen whether these or other business models will be
            subject to regulation in the future, possibly with MiCA II.
          </p>
        </section>
      </div>
    </section>
  );
}
