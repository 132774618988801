import Lottie from "lottie-react";

interface AnimationProps {
  animationData: any;
  className?: string;
  autoplay?: boolean;
  loop?: boolean;
}

export default function Animation({
  animationData,
  className,
  autoplay,
  loop,
}: AnimationProps) {
  return (
    <Lottie
      animationData={animationData}
      loop={loop != null ? loop : true}
      autoplay={autoplay != null ? autoplay : true}
      className={className}
    />
  );
}
