import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Drawer } from "vaul";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import NavigationLink from "./NavigationLink";
import Hamburger from "hamburger-react";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Navigation() {
  const [scroll, setScroll] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      id="nav"
      className={classNames(
        "px-8 lg:px-0 w-full fixed top-0 bg-slate-100 dark:bg-gray-900 flex justify-center z-50 bg-opacity-80 backdrop-blur-md dark:bg-opacity-40 transition-all duration-300 shadow-md",
        scroll ? "py-4" : "py-6"
      )}
    >
      <div className="inline-block md:hidden">
        <Drawer.Root onOpenChange={setMenuIsOpen} open={menuIsOpen}>
          <Drawer.Trigger>
            <div className="hidden dark:block">
              <Hamburger toggled={menuIsOpen} size={20} color="#ffffff" />
            </div>
            <div className="block dark:hidden">
              <Hamburger toggled={menuIsOpen} size={20} />
            </div>
          </Drawer.Trigger>
          <Drawer.Portal>
            <Drawer.Overlay className="fixed inset-0 bg-black/40 dark:bg-black/50" />
            <Drawer.Content
              className="bg-zinc-100 dark:bg-zinc-800 flex flex-col items-start justify-start gap-8 rounded-t-[18px] fixed bottom-0 left-0 right-0 p-8"
              style={{ height: "calc(100vh - 220px)" }}
            >
              <NavigationLink
                href="/about-us"
                className="text-xl font-medium duration-500 text-svlaw-blue-light dark:text-slate-300"
                activeClassName="text-svlaw-gold font-bold"
                onClick={() => {
                  setMenuIsOpen(false);
                }}
              >
                About Us
              </NavigationLink>

              <NavigationLink
                href="/mica"
                className="text-xl font-medium duration-500 text-svlaw-blue-light dark:text-slate-300"
                activeClassName="text-svlaw-gold font-bold"
                onClick={() => {
                  setMenuIsOpen(false);
                }}
              >
                MiCA Overview
              </NavigationLink>
              <NavigationLink
                href="/token-checker"
                className="text-xl font-medium duration-500 text-svlaw-blue-light dark:text-slate-300"
                activeClassName="text-svlaw-gold font-bold"
                onClick={() => {
                  setMenuIsOpen(false);
                }}
              >
                Token Checker
              </NavigationLink>
              <NavigationLink
                href="/insights"
                className="text-xl font-medium duration-500 text-svlaw-blue-light dark:text-slate-300"
                activeClassName="text-svlaw-gold font-bold"
                onClick={() => {
                  setMenuIsOpen(false);
                }}
              >
                Insights
              </NavigationLink>
              <NavigationLink
                href="/resources"
                className="text-xl font-medium duration-500 text-svlaw-blue-light dark:text-slate-300"
                activeClassName="text-svlaw-gold font-bold"
                onClick={() => {
                  setMenuIsOpen(false);
                }}
              >
                Resources
              </NavigationLink>
              <NavigationLink
                href="/contact"
                className="text-xl font-medium duration-500 text-svlaw-blue-light dark:text-slate-300"
                activeClassName="text-svlaw-gold font-bold"
                onClick={() => {
                  setMenuIsOpen(false);
                }}
              >
                Contact
              </NavigationLink>
            </Drawer.Content>
          </Drawer.Portal>
        </Drawer.Root>
      </div>
      <div className="flex flex-col md:flex-row items-center justify-center gap-4 md:gap-0 w-full lg:max-w-[1000px]">
        <Link to={process.env.REACT_APP_WEBSITE_URL!} title="Go MiCA">
          <img
            src="/images/gomica-logo.svg"
            alt="GoMiCA - Your Gateway to MiCA Compliance"
            title="GoMiCA - Your Gateway to MiCA Compliance"
            className={classNames(
              "w-auto hidden dark:block transition-all duration-200",
              scroll ? "h-6" : "h-9"
            )}
          />
          <img
            src="/images/go-mica-logo.png"
            alt="GoMiCA - Your Gateway to MiCA Compliance"
            title="GoMiCA - Your Gateway to MiCA Compliance"
            className={classNames(
              "w-auto block dark:hidden transition-all duration-200",
              scroll ? "h-6" : "h-9"
            )}
          />
        </Link>
        <div className="hidden md:flex flex-row gap-3 md:gap-8 md:ml-auto items-center justify-center">
          <NavigationLink
            href="/about-us"
            className="text-xs md:text-sm font-bold duration-500 text-svlaw-blue-light dark:text-slate-300 hover:text-svlaw-gold"
            activeClassName="border-b-2 border-b-svlaw-blue-light hover:border-b-svlaw-gold"
          >
            About Us
          </NavigationLink>

          <Menu as="div" className="relative inline-flex text-left">
            <Menu.Button className="inline-flex text-xs md:text-sm font-bold duration-500 text-svlaw-blue-light dark:text-slate-300 hover:text-svlaw-gold">
              MiCA Overview
              <ChevronDownIcon
                className="h-4 w-3 md:h-5 md:w-5 text-svlaw-blue-light dark:text-slate-300"
                aria-hidden="true"
              />
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute left-[-25%] md:right-0 md:left-auto top-6 z-10 mt-2 w-[280px] md:w-[300px] origin-top-right rounded-md bg-zinc-50 dark:bg-gray-900 shadow-lg ring-1 ring-black dark:ring-gray-500 ring-opacity-5 focus:outline-none">
                <div className="py-1 bg-none text-left md:text-right">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/mica/basic-principles"
                        className={classNames(
                          active
                            ? "bg-gray-100/80 dark:bg-gray-100/20 text-zinc-900 dark:text-zinc-50"
                            : "text-zinc-700 dark:text-zinc-300",
                          "transition-colors duration-300 block px-4 py-2 text-sm"
                        )}
                      >
                        Basic principles
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/mica/public-offer-of-crypto-assets"
                        className={classNames(
                          active
                            ? "bg-gray-100/80 dark:bg-gray-100/20 text-zinc-900 dark:text-zinc-50"
                            : "text-zinc-700 dark:text-zinc-300",
                          "transition-colors duration-300 block px-4 py-2 text-sm"
                        )}
                      >
                        Public offer of crypto-assets
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/mica/crypto-asset-services"
                        className={classNames(
                          active
                            ? "bg-gray-100/80 dark:bg-gray-100/20 text-zinc-900 dark:text-zinc-50"
                            : "text-zinc-700 dark:text-zinc-300",
                          "transition-colors duration-300 block px-4 py-2 text-sm"
                        )}
                      >
                        Crypto-asset services
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/mica/insider-dealing-and-market-manipulation"
                        className={classNames(
                          active
                            ? "bg-gray-100/80 dark:bg-gray-100/20 text-zinc-900 dark:text-zinc-50"
                            : "text-zinc-700 dark:text-zinc-300",
                          "transition-colors duration-300 block px-4 py-2 text-sm"
                        )}
                      >
                        Insider dealing / market manipulation
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/mica/unregulated-business-models"
                        className={classNames(
                          active
                            ? "bg-gray-100/80 dark:bg-gray-100/20 text-zinc-900 dark:text-zinc-50"
                            : "text-zinc-700 dark:text-zinc-300",
                          "transition-colors duration-300 block px-4 py-2 text-sm"
                        )}
                      >
                        Unregulated business models
                      </Link>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>

          <NavigationLink
            href="/token-checker"
            className="text-xs md:text-sm font-bold duration-500 text-svlaw-blue-light dark:text-slate-300 hover:text-svlaw-gold"
            activeClassName="border-b-2 border-b-svlaw-blue-light hover:border-b-svlaw-gold"
          >
            Token Checker
          </NavigationLink>

          <NavigationLink
            href="/insights"
            className="text-xs md:text-sm font-bold duration-500 text-svlaw-blue-light dark:text-slate-300 hover:text-svlaw-gold"
            activeClassName="border-b-2 border-b-svlaw-blue-light hover:border-b-svlaw-gold"
          >
            Insights
          </NavigationLink>

          <NavigationLink
            href="/resources"
            className="text-xs md:text-sm font-bold duration-500 text-svlaw-blue-light dark:text-slate-300 hover:text-svlaw-gold"
            activeClassName="border-b-2 border-b-svlaw-blue-light hover:border-b-svlaw-gold"
          >
            Resources
          </NavigationLink>

          <NavigationLink
            href="/contact"
            className="text-xs md:text-sm font-bold duration-500 text-svlaw-blue-light dark:text-slate-300 hover:text-svlaw-gold"
            activeClassName="border-b-2 border-b-svlaw-blue-light hover:border-b-svlaw-gold"
          >
            Contact
          </NavigationLink>
        </div>
      </div>
    </nav>
  );
}
