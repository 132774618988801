import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { HomeIcon } from "@heroicons/react/24/solid";
import MicaChapterNavigation from "../../MicaChapterNavigation";
import micaChapters from "../../..//MicaChapters.json";
import AnchorButton from "../../../AnchorButton";
import MobileMicaChapterNavigation from "../../MobileMicaChapterNavigation";

export default function BasicPrinciples() {
  return (
    <section className="px-4 lg:px-0">
      {/* Chapter-Navigation */}
      <MicaChapterNavigation urls={micaChapters} />
      {/* Breadcrumbs */}
      <div className="flex flex-row gap-2 mb-8 rounded-md bg-zinc-50/90 dark:bg-slate-950/80 shadow-sm px-4 py-2 static md:sticky md:top-24 backdrop-blur-md border-2 border-zinc-100 dark:border-slate-800">
        <Link
          to="/mica"
          className="text-slate-400 font-bold inline-flex flex-row gap-1 items-center"
        >
          <HomeIcon className="text-slate-400 w-4" /> MiCA
        </Link>
        <ChevronRightIcon className="text-slate-600 w-4" />
        <span className="text-slate-600 font-bold dark:text-slate-50 hidden md:block">
          Basic principles
        </span>
        <MobileMicaChapterNavigation urls={micaChapters}>
          <span className="text-slate-600 font-bold dark:text-slate-50">
            Basic principles
          </span>
        </MobileMicaChapterNavigation>
      </div>

      <h4 className="text-md text-zinc-600 dark:text-zinc-400">
        Markets in Crypto-Assets Regulation (MiCA)
      </h4>
      <h1
        className="text-4xl text-svlaw-blue-dark mb-1 dark:text-svlaw-gold"
        id="basic-principles"
      >
        Basic principles <AnchorButton href="#basic-principles" />
      </h1>

      <section>
        <h2
          className="text-3xl text-svlaw-blue-light mb-1 mt-7 dark:text-sky-200"
          id="scope"
        >
          1. Scope <AnchorButton href="#scope" />
        </h2>
        <p className="text-justify">
          MiCA applies to all natural and legal persons that are engaged in the
          issuance, offer to the public and admission to trading of
          crypto-assets or that provide services related to crypto-assets in the
          European Union.
        </p>
        <ul className="list-disc list-outside ml-4 text-justify">
          <li className="py-1">
            An <b>issuance</b> refers to the first sale of a crypto-asset of an
            issuer. This can, but does not have to, take place within the
            framework of a public offering.
          </li>
          <li className="py-1">
            An <b>offer to the public</b> means a communication to persons in
            any form, and by any means, presenting sufficient information on the
            terms of the offer and the crypto-assets to be offered so as to
            enable prospective holders to decide whether to purchase those
            crypto-assets. Publishing information on a website can qualify as an
            offer to the public. An offer to the public is not necessarily
            limited to the issuer; it can be made by anyone who (re)sells the
            crypto-asset.
          </li>
          <li className="py-1">
            <b>Admission to trading</b> refers to the listing of crypto-assets
            on a (licensed) crypto-asset exchange in the Union. MiCA may
            therefore be relevant even if the issuer is not domiciled in the
            Union and there is no public offer within the Union.
          </li>
          <li className="py-1">
            <b>Crypto-asset services</b> are custody and administration,
            operation of a trading platform, exchange of crypto-assets for funds
            or other crypto-assets, execution of orders, placing, reception and
            transmission of orders, advisory services, portfolio management, and
            transfer services.
          </li>
        </ul>
        <p>
          The scope of application thus covers a wide range of activities known
          on the market. If an exception to the scope applies (see below), or if
          an activity is not regulated under MiCA, the Regulation is not
          relevant. Such activities can therefore be carried out without
          observing the provisions in MiCA.
        </p>
      </section>
      <section>
        <h2
          className="text-3xl text-svlaw-blue-light mb-1 mt-7 dark:text-sky-200"
          id="concept-of-crypto-asset"
        >
          2. Concept of crypto-asset{" "}
          <AnchorButton href="#concept-of-crypto-asset" />
        </h2>
        <p className="text-justify">
          The central concept of MiCA is the <i>crypto-asset</i>. MiCA defines
          crypto-asset as a digital representation of a value or of a right that
          is able to be transferred and stored electronically using distributed
          ledger technology or similar technology. This broad term covers most
          commonly known cryptocurrencies, including the ten largest in terms of
          market capitalization at the time of publication of this series:
          Bitcoin, Ether, Tether, Binance Coin, USD Coin, Ripple, Cardano,
          Dogecoin, Polygon, and Solana.
        </p>
        <p className="pt-2 text-justify">
          To qualify as a crypto-asset, the cryptocurrency must digitally
          represent a value or a right. The term is broader than the concept of
          <i>virtual currency</i> introduced in EU law in 2020, which required
          (among other things) that the cryptocurrency is accepted as a means of
          exchange. All cryptocurrencies that qualify as virtual currencies also
          qualify as crypto-assets under MiCA.
        </p>
      </section>
      <section>
        <h2
          className="text-3xl text-svlaw-blue-light mb-1 mt-7 dark:text-sky-200"
          id="exceptions"
        >
          3. Exceptions from the scope of application{" "}
          <AnchorButton href="#exceptions" />
        </h2>
        <p className="pb-2 text-justify">
          There are several exceptions to the scope of MiCA. The exceptions
          relate either to the characteristics of the crypto-asset (e.g. NFTs,
          financial instruments) or the respective actors (e.g. reverse
          solicitation, group exception):
        </p>
        <ul className="list-disc list-outside ml-4 text-justify">
          <li className="py-1">
            <b>Non-fungible tokens (NFTs)</b> are excluded from the scope of
            MiCA so long as they are unique and not fungible with other
            crypto-assets. The prototypical example would be NFT artwork. In
            contrast, if an NFT token standard such as ERC-721 or ERC-1155 is
            used to issue interchangeable NFT-based vouchers, the exclusion
            would not apply. Although these tokens would be non-fungible from a
            technical perspective, they would not be unique. Under MiCA, a
            substance-over-form approach applies. Relying on specific technical
            standards therefore cannot be used to circumvent MiCA&apos;s
            requirements.
          </li>
          <li className="py-1">
            <b>Financial instruments</b> are also excluded from the scope of
            MiCA. This is relevant if rights are embodied in a token that are
            typically associated with financial instruments, e.g. the right to
            interest and repayment as with a bond. In such a case, the token
            merely serves as a carrier medium for the respective right.
            Formally, such tokens qualify as crypto-assets; however, because
            other EU legal acts already regulate financial instruments, such
            tokens are exempt from MiCA. For example, MiFID II would apply to
            the distribution of tokenized financial instruments and the
            Prospectus Regulation would apply to the public offer of tokenized
            transferable securities. In addition to financial instruments, a
            number of other instruments already regulated by EU law are excluded
            from the scope of MiCA.
          </li>
          <li className="py-1">
            <b>Reverse solicitation</b>, the principle known from MiFID II,
            according to which non-EU firms are not required to obtain a license
            in an EU client&apos;s country of residence if the services are
            provided at the client&apos;s own exclusive initiative, can also be
            found in MiCA. It means that crypto-asset service providers
            established outside the EU can provide crypto-asset services to EU
            clients without obtaining a license under MiCA, provided that the
            services are provided at the client&apos;s own exclusive initiative.
            However, MiCA contains several provisions aimed against possible
            abuse of reverse solicitation. For example, reverse solicitation
            does not apply if the third-country firm directly or indirectly
            solicits clients or prospective clients in the Union. Even
            contractual clauses or disclaimers stating otherwise are disregarded
            under MiCA. In addition, third-country firms may not market new
            products or services to clients acquired by reverse solicitation
            without losing the benefit of the exemption.
          </li>
          <li className="py-1">
            <b>Group companies</b> are excluded from the scope of application
            under MiCA if they provide their services exclusively internally
            within the group for other group companies. Such group companies
            will therefore also not have to comply with MiCA. The provision is
            relevant for internationally operating service providers that divide
            functions among different group companies (for example, if they
            divide the infrastructure for custody and for operating a trading
            platform among several group companies). MiCA contains a safeguard
            against abuse of this provision: If activities are outsourced to
            other group companies, MiCA sets out detailed requirements that must
            be met.
          </li>
        </ul>
      </section>
      <section>
        <h2
          className="text-3xl text-svlaw-blue-light mb-1 mt-7  dark:text-sky-200"
          id="new-categories"
        >
          4. New categories of crypto-assets{" "}
          <AnchorButton href="#new-categories" />
        </h2>
        <p className="pb-2 text-justify">
          In addition to the general concept of crypto-asset, MiCA introduces
          three distinct subcategories of crypto-asset, namely asset-referenced
          tokens, e-money tokens, and utility tokens:
        </p>
        <ul className="list-disc list-outside ml-4 text-justify">
          <li className="py-1">
            <b>Asset-referenced token</b> is a type of crypto-asset that
            purports to maintain a stable value by referencing another value or
            right or a combination thereof, including one or more official
            currencies. Asset-referenced tokens are therefore intended to
            maintain a stable value with respect to other reference values. The
            scope of application is wide: any other value and right can be
            considered, such as gold, real estate, fund shares, stocks, bonds or
            even other crypto-assets.
          </li>
          <li className="py-1">
            <b>E-money token</b> is a type of crypto-asset that purports to
            maintain a stable value by referencing the value of one official
            currency. E-money tokens are similar to asset-referenced tokens,
            except that they reference the value of only a single currency such
            as euros or U.S. dollars.
          </li>
          <li className="py-1">
            <b>Utility token</b> is a type of crypto-asset that is only intended
            to provide access to a good or a service supplied by its issuer.
            MiCA distinguishes between utility tokens where the good or service
            already exists or is already provided, and those where the issuance
            of the token serves as an advance sale for project financing.
          </li>
        </ul>
        <p className="pt-2 text-justify">
          Special provisions apply to the public offering of these
          crypto-assets, which we will highlight later. Not every crypto-asset
          falls into one of these categories, however. If a crypto-asset does
          not fall into one of them, the general requirements for the public
          offering of crypto-assets outlined below still apply.
        </p>
      </section>
    </section>
  );
}
