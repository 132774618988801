import { Email } from "react-obfuscate-email";

export default function Imprint() {
  return (
    <>
      <h1 className="text-4xl text-svlaw-blue-light dark:text-svlaw-gold mb-4">
        Legal Notice
      </h1>
      <h2 className="font-bold">
        Disclosure in accordance with the Austrian E-Commerce Act, the
        Commercial Code and the Media Act
      </h2>

      <div className="flex flex-col md:flex-row items-start justify-start gap-8 mt-8">
        <p className="flex-shrink-0">
          <b>Stadler Völkel Rechtsanwälte GmbH</b>
          <br />
          Seilerstätte 24, 1010 Vienna, Austria
          <br />
          Tel.: +43 1 997 1025
          <br />
          Fax: +43 1 997 1025 99
          <br />
          E-Mail: <Email email="office@sv.law">office@sv.law</Email>
        </p>
        <div>
          <p>
            Commercial Register No. FN426839f, Commercial Court Vienna
            <br />
            VAT No.: ATU69192517
          </p>
          <div className="w-full border-b-2 border-b-slate-500 dark:border-b-slate-700 my-2" />
          <p className="leading-8">
            The business purpose of the company is providing legal services.
            <br />
            Chamber: Vienna Bar Association (
            <a href="https://www.rakwien.at" target="_blank" rel="noreferrer">
              www.rakwien.at
            </a>
            ).
            <br />
            Legal and professional law provisions:{" "}
            <a
              href="https://www.rakwien.at/?seite=anwaelte&bereich=standesrecht"
              target="_blank"
              rel="noreferrer"
            >
              Link
            </a>
            .
            <br />
            <br />
            The European Commission is providing a platform for Online Dispute
            Resolution (ODR) under{" "}
            <a
              href="https://ec.europa.eu/consumers/odr/"
              target="_blank"
              rel="noreferrer"
            >
              https://ec.europa.eu/consumers/odr/
            </a>
            . We are not obliged nor willing to participate in a dispute
            settlement procedure before a consumer arbitration board.
          </p>
        </div>
      </div>
    </>
  );
}
