import { useState } from "react";
import Animation from "../../Animation";
import { useCaptchaHook } from "@aacn.eu/use-friendly-captcha";
import { CgSpinnerAlt } from "react-icons/cg";
import mailAnimation from "../../animations/mail_animation.json";

interface IFormData {
  name: string;
  email: string;
  message: string;
  solution?: string | null;
}

async function submitMessage(formData: IFormData): Promise<{
  error?: string | null;
  message?: string | null;
}> {
  try {
    if (formData.name && formData.email && formData.message) {
      if (formData.solution) {
        const { success, error, message } = await (
          await fetch(`${process.env.REACT_APP_CONTACT_FORM_ACTION}`, {
            method: "POST",
            body: JSON.stringify(formData),
            headers: {
              "Content-Type": "application/json",
            },
          })
        ).json();
        if (success) {
          return { message };
        }

        return { error };
      } else {
        return {
          error:
            "You have to verify that you are not a robot using the captcha below.",
        };
      }
    }
  } catch {
    return { error: "There was an error. Please try again later." };
  }

  return { error: "Please fill in all the form inputs." };
}

export default function Contact() {
  const captcha = useCaptchaHook({
    siteKey: "FCMT3D1UD1099SDB",
    showAttribution: false,
    language: "en",
    startMode: "none",
  });

  const [formData, setFormData] = useState<IFormData>({
    name: "",
    email: "",
    message: "",
    solution: undefined,
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  return (
    <section className="px-4 lg:px-0">
      <h1 className="text-4xl text-svlaw-blue-light dark:text-svlaw-gold mb-4">
        Contact
      </h1>
      {success && (
        <div className="flex flex-col mt-4 justify-center items-center">
          <div>
            <Animation
              animationData={mailAnimation}
              loop={false}
              className="w-72 h-56 max-w-xs p-0 m-0"
            />
          </div>
          <span className="text-2xl text-slate-700 dark:text-slate-200">
            {success}
          </span>
          <span className="text-xl text-slate-500 dark:text-slate-400">
            We will get back to you soon.
          </span>
        </div>
      )}
      {error && <div className="text-red-500">{error}</div>}

      {!success && (
        <>
          <p className="my-3">
            If you want to contact us, please feel free to send us a message
            using the contact form below.
          </p>
          <div className="flex flex-col gap-4 w-full max-w-lg items-start justify-start z-10">
            <input
              type="text"
              placeholder="Your name"
              required
              className="w-full rounded-md py-2 px-4 border-2 shadow-inner focus:outline-svlaw-gold dark:bg-slate-800 dark:border-slate-700 focus:outline-none"
              onChange={(e) => {
                setFormData({ ...formData, name: e.target.value });
              }}
            />
            <input
              type="email"
              placeholder="Your e-mail address"
              required
              className="w-full rounded-md py-2 px-4 border-2 shadow-inner focus:outline-svlaw-gold dark:bg-slate-800 dark:border-slate-700 focus:outline-none"
              onChange={(e) => {
                setFormData({ ...formData, email: e.target.value });
              }}
            />
            <textarea
              placeholder="Your message"
              required
              className="w-full rounded-md py-2 px-4 border-2 shadow-inner focus:outline-svlaw-gold dark:bg-slate-800 dark:border-slate-700 focus:outline-none"
              onChange={(e) => {
                setFormData({ ...formData, message: e.target.value });
              }}
            />
            {captcha.CaptchaWidget({
              className:
                "w-auto h-full py-4 px-4 bg-zinc-50 text-zinc-800 rounded-lg shadow-xs border-2 border-zinc-600",
            })}
            <button
              type="button"
              className="border-2 border-svlaw-blue-dark duration-300 hover:bg-svlaw-gold rounded-md px-6 py-2 bg-svlaw-blue-light text-white shadow-md disabled:cursor-not-allowed disabled:hover:bg-svlaw-blue-dark disabled:opacity-50 flex flex-row items-center gap-4 focus:outline-none"
              disabled={
                !captcha.captchaStatus.solution ||
                !formData.name ||
                !formData.email ||
                !formData.message ||
                loading
              }
              onClick={async () => {
                setLoading(true);
                const { error, message } = await submitMessage({
                  ...formData,
                  solution: captcha.captchaStatus.solution || undefined,
                });

                if (message) {
                  setSuccess(message);
                  setError(null);
                } else {
                  setSuccess(null);
                  setError(error ?? "Error while submitting the form.");
                }

                setLoading(false);
              }}
            >
              Send my message{" "}
              {loading && (
                <span className="text-white animate-spin">
                  <CgSpinnerAlt />
                </span>
              )}
            </button>
          </div>
        </>
      )}
    </section>
  );
}
