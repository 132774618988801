export default function DataProtection() {
  return (
    <>
      <h1 className="text-4xl text-svlaw-blue-light dark:text-svlaw-gold mb-4">
        Data Protection
      </h1>
      <p className="text-lg">
        Data Protection Declaration of Stadler Völkel Attorneys at Law
        concerning the domain "gomica.eu".
      </p>
      <p className="mt-2">Last Update: August 2023</p>
      <p className="mt-2">
        View our Data Protection Declaration as a{" "}
        <a
          href="/DSE-EN_MiCA_2023-08-02.pdf"
          target="_blank"
          title="Latest Data Protection Declaration as PDF"
          className="underline underline-offset-2"
        >
          PDF-File
        </a>
        .
      </p>
    </>
  );
}
