export default function AboutUs() {
  return (
    <>
      <img
        src="/images/svlaw.jpg"
        alt="STADLER VÖLKEL Rechtsanwälte"
        className="rounded-xl shadow-xl mt-2 mb-6"
      />
      <section>
        <h1 className="text-4xl text-svlaw-blue-light dark:text-svlaw-gold mb-4 mt-8 md:mt-12">
          About Us
        </h1>
        <div className="flex flex-col sm:flex-row gap-8 justify-start items-start">
          <p className="text-lg text-justify">
            STADLER VÖLKEL is one of the leading law firms in Europe for
            cryptocurrency law. Founded in 2016 in Vienna, Austria, the firm has
            advised on groundbreaking projects related to the legal
            classification of Bitcoin, public token offerings, decentralized
            finance and more. STADLER VÖLKEL is known for its expertise in a
            wide range of legal areas, including corporate law, financial
            regulation, capital markets law, banking and finance law, data
            protection law, betting and gaming law, antitrust law and
            litigation.
          </p>
          <a
            href="https://sv.law/en"
            title="STADLER VÖLKEL Website"
            target="_blank"
            rel="noreferrer"
            className="flex-shrink-0 hidden sm:block"
          >
            <img
              src="/images/svlaw-logo-blau.jpg"
              alt="STADLER VÖLKEL Logo"
              className="w-44 rounded-md shadow-xl hover:scale-110 transition-all duration-500"
            />
          </a>
        </div>
      </section>
      <section className="mt-8 md:mt-12">
        <h1 className="text-4xl text-svlaw-blue-light dark:text-svlaw-gold">
          Team
        </h1>
        <h2 className="text-2xl text-svlaw-blue-dark dark:text-svlaw-gold/80 mt-2">
          Ask our experts
        </h2>
        <div className="flex justify-start w-full">
          <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-6 gap-8 items-center justify-center mt-4 w-full">
            <a
              href="https://www.sv.law/en/mitarbeiter/oliver-voelkel"
              target="_blank"
              title="Oliver Völkel"
              rel="noreferrer"
            >
              <div className="flex flex-col items-center justify-start gap-3">
                <img
                  src="/images/nfts/OV.png"
                  alt="Oliver Völkel"
                  className="rounded-xl shadow-xl hover:scale-110 transition-all duration-500"
                />
                <span className="font-bold text-md text-svlaw-blue-dark dark:text-svlaw-gold">
                  Oliver Völkel
                </span>
              </div>
            </a>
            <a
              href="https://www.sv.law/en/mitarbeiter/bryan-hollmann"
              target="_blank"
              title="Bryan Hollmann"
              rel="noreferrer"
            >
              <div className="flex flex-col items-center justify-start gap-3">
                <img
                  src="/images/nfts/BH.png"
                  alt="Bryan Hollmann"
                  className="rounded-xl shadow-xl hover:scale-110 transition-all duration-500"
                />
                <span className="font-bold text-md text-svlaw-blue-dark dark:text-svlaw-gold">
                  Bryan Hollmann
                </span>
              </div>
            </a>
            <a
              href="https://www.sv.law/en/mitarbeiter/philipp-ley"
              target="_blank"
              title="Philipp Ley"
              rel="noreferrer"
            >
              <div className="flex flex-col items-center justify-start gap-3">
                <img
                  src="/images/nfts/PL.png"
                  alt="Philipp Ley"
                  className="rounded-xl shadow-xl hover:scale-110 transition-all duration-500"
                />
                <span className="font-bold text-md text-svlaw-blue-dark dark:text-svlaw-gold">
                  Philipp Ley
                </span>
              </div>
            </a>
            <a
              href="https://www.sv.law/en/mitarbeiter/urim-bajrami"
              target="_blank"
              title="Urim Bajrami"
              rel="noreferrer"
            >
              <div className="flex flex-col items-center justify-start gap-3">
                <img
                  src="/images/nfts/UB.png"
                  alt="Urim Bajrami"
                  className="rounded-xl shadow-xl hover:scale-110 transition-all duration-500"
                />
                <span className="font-bold text-md text-svlaw-blue-dark dark:text-svlaw-gold">
                  Urim Bajrami
                </span>
              </div>
            </a>
            <a
              href="https://www.sv.law/en/mitarbeiter/gregor-schuetz"
              target="_blank"
              title="Gregor Schütz"
              rel="noreferrer"
            >
              <div className="flex flex-col items-center justify-start gap-3">
                <img
                  src="/images/nfts/GS.png"
                  alt="Gregor Schütz"
                  className="rounded-xl shadow-xl hover:scale-110 transition-all duration-500"
                />
                <span className="font-bold text-md text-svlaw-blue-dark dark:text-svlaw-gold">
                  Gregor Schütz
                </span>
              </div>
            </a>
            <a
              href="https://www.sv.law/en/mitarbeiter/maya-kindler"
              target="_blank"
              title="Maya Kindler"
              rel="noreferrer"
            >
              <div className="flex flex-col items-center justify-start gap-3">
                <img
                  src="/images/nfts/MK.png"
                  alt="Maya Kindler"
                  className="rounded-xl shadow-xl hover:scale-110 transition-all duration-500"
                />
                <span className="font-bold text-md text-svlaw-blue-dark dark:text-svlaw-gold">
                  Maya Kindler
                </span>
              </div>
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
