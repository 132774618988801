import React, { useEffect } from "react";
import { BsFacebook, BsInstagram, BsLinkedin } from "react-icons/bs";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Home from "./components/Home";
import { ScrollToTop } from "./components/ScrollToTop";
import Navigation from "./components/Navigation";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import BasicPrinciples from "./components/MiCA/BasicPrinciples";
import PublicOfferOfCryptoAssets from "./components/MiCA/PublicOfferOfCryptoAssets";
import CryptoAssetServices from "./components/MiCA/CryptoAssetServices";
import InsiderDealingAndMarketManipulation from "./components/MiCA/InsiderDealingAndMarketManipulation";
import UnregulatedBusinessModels from "./components/MiCA/UnregulatedBusinessModels";
import QuestionnaireLandingPage from "./components/questionnaire";
import QuestionnaireWhitepaper from "./components/questionnaire/whitepaper";
import Resources from "./components/Resources";
import Contact from "./components/Contact";
import AboutUs from "./components/AboutUs";
import MiCAOverview from "./components/MiCA";
import Imprint from "./components/Imprint";
import DataProtection from "./components/DataProtection";
import Insights from "./components/Insights";
import InsightArticle from "./components/Insights/InsightArticle";

// wird gebraucht, um bei einer page-transition automatisch nach oben zu scrollen
function ScrollToTopObserver() {
  const { pathname } = useLocation();

  useEffect(() => {
    // wenn kein Anchor angegeben, dann scrollen
    if (pathname.indexOf("#") === -1) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
}

export default function App() {
  const queryClient = new QueryClient();

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      const script = document.createElement("script");
      script.setAttribute("defer", "true");
      script.setAttribute("data-domain", "gomica.eu");
      script.src = "https://analytics.svlaw.at/js/plausible.js";

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  return (
    <>
      <ScrollToTopObserver />
      <ScrollToTop />
      <Navigation />
      <main className="w-full lg:w-[1000px] min-h-0 md:min-h-[60vh] px-4 pt-8 lg:pt-0 mb-8 md:mb-0 mt-[100px] lg:mt-[120px]">
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/mica" element={<MiCAOverview />} />
            <Route path="/mica">
              <Route path="basic-principles" element={<BasicPrinciples />} />
              <Route
                path="public-offer-of-crypto-assets"
                element={<PublicOfferOfCryptoAssets />}
              />
              <Route
                path="crypto-asset-services"
                element={<CryptoAssetServices />}
              />
              <Route
                path="insider-dealing-and-market-manipulation"
                element={<InsiderDealingAndMarketManipulation />}
              />
              <Route
                path="unregulated-business-models"
                element={<UnregulatedBusinessModels />}
              />
            </Route>
            <Route
              path="/token-checker"
              element={<QuestionnaireLandingPage />}
            />
            <Route
              path="/token-checker/whitepaper"
              element={<QuestionnaireWhitepaper />}
            />
            <Route path="/insights" element={<Insights />} />
            <Route path="/insights/:slug" element={<InsightArticle />} />
            <Route path="/resources" element={<Resources />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/imprint" element={<Imprint />} />
            <Route path="/data-protection" element={<DataProtection />} />
          </Routes>
        </QueryClientProvider>
      </main>
      <footer className="w-full lg:w-[1000px] md:mt-6 px-2 md:px-4">
        <div className="border-t-2 border-gray-200 dark:border-gray-700 justify-center items-center text-gray-500 py-5 flex flex-col gap-4">
          <div className="text-xs">
            <Link
              to="/imprint"
              className="text-xs hover:text-svlaw-gold transition-colors duration-300"
            >
              Imprint
            </Link>{" "}
            |{" "}
            <Link
              to="/data-protection"
              className="text-xs hover:text-svlaw-gold transition-colors duration-300"
            >
              Data Protection
            </Link>
          </div>
          <div className="flex justify-center w-full items-center">
            <a
              href="https://sv.law/en/"
              title="Stadler Völkel Rechtsanwälte GmbH"
              className="hover:scale-110 transition-all duration-300"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/images/svlaw-logo-light.svg"
                alt="Stadler Völkel Rechtsanwälte GmbH"
                title="Stadler Völkel Rechtsanwälte GmbH"
                className="h-6 w-auto hidden dark:block"
              />
              <img
                src="/images/svlaw-logo-dark.svg"
                alt="Stadler Völkel Rechtsanwälte GmbH"
                title="Stadler Völkel Rechtsanwälte GmbH"
                className="h-6 w-auto block dark:hidden"
              />
            </a>
          </div>
          <div className="flex flex-row gap-6 justify-center items-center">
            <a
              href="https://www.facebook.com/StadlerVoelkelRechtsanwaelte"
              title="STADLER VÖLKEL on Facebook"
              target="_blank"
              className="hover:text-sky-400 transition-all duration-300 hover:scale-125"
              rel="noreferrer"
            >
              <BsFacebook />
            </a>
            <a
              href="https://www.instagram.com/stadler_voelkel"
              title="STADLER VÖLKEL on Instagram"
              target="_blank"
              className="hover:text-sky-400 transition-all duration-300 hover:scale-125"
              rel="noreferrer"
            >
              <BsInstagram />
            </a>
            <a
              href="https://at.linkedin.com/company/stadler-voelkel-rechtsanwaelte"
              title="STADLER VÖLKEL on LinkedIn"
              target="_blank"
              className="hover:text-sky-400 transition-all duration-300 hover:scale-125"
              rel="noreferrer"
            >
              <BsLinkedin />
            </a>
          </div>
        </div>
      </footer>
    </>
  );
}
